import Card from "./Card";
import StatisticChart from "../../components/Chart/StatisticChart";
import DateRangePicker from "../../components/DateRangePicker";
import { useEffect, useState } from "react";
import { getDashBoardKPI } from "../../api/Dashboard/getDashBoardKPI";
import { useAppSelector } from "../../app/hooks";
import moment from "moment";

export default function DashboardNew() {
  const [kpiInfo, setKPIinfo] = useState<any>([]);
  var kpiCard = [
    {
      name: "Total card generated",
      color: "#d7f9ef",
      value: kpiInfo?.total_cards_generated || 0,
    },
    {
      name: "Total patient visited",
      color: "#FFCDCD",
      value: kpiInfo?.total_patients_visited || 0,
    },
    {
      name: "Total lost cards",
      color: "#DAFFD1",
      value: kpiInfo?.lost_card_count || 0,
    },
  ];

  const { accessToken } = useAppSelector((state) => state.user);
  const GetKPIinfo = async ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    const res = await getDashBoardKPI({
      key: accessToken,
      startDate: startDate,
      endDate: endDate,
    });

    if (res?.status === "success") {
      setKPIinfo(res?.data);

      kpiCard = [
        {
          name: "Total card generated",
          color: "#d7f9ef",
          value: res?.data?.total_cards_generated || 0,
        },
        {
          name: "Total patient visited",
          color: "#FFCDCD",
          value: res?.data?.total_patients_visited || 0,
        },
        {
          name: "Total lost cards",
          color: "#DAFFD1",
          value: res?.data?.lost_card_count || 0,
        },
      ];
    }
  };

  useEffect(() => {
    GetKPIinfo({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
  }, []);

  return (
    <div className="w-full bg-gray-50 " style={{ minHeight: "765px" }}>
      <div className=" py-2 flex items-center justify-end pr-4">
        <div>
          <DateRangePicker
            onDateRangeChnage={(e) => {
              const startDate = moment(e[0]?.startDate).format("YYYY-MM-DD");
              const endDate = moment(e[0]?.endDate).format("YYYY-MM-DD");

              GetKPIinfo({
                startDate: startDate,
                endDate: endDate,
              });
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-5 p-5">
        {kpiCard?.map((data) => {
          return (
            <Card text={data?.name} color={data?.color} number={data?.value} />
          );
        })}
        <div className="sm:col-span-4 border p-3 rounded-lg bg-white">
          <div className="font-semibold text-lg">
            Patient visited to branches
          </div>
          {kpiInfo?.patient_stats_by_branch && (
            <StatisticChart data={kpiInfo?.patient_stats_by_branch} />
          )}
        </div>
      </div>
    </div>
  );
}
