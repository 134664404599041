import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Filter,
  Toolbar,
  PdfExport,
  ExcelExport,
  Sort,
  Resize,
  SelectionSettingsModel,
  Column,
} from "@syncfusion/ej2-react-grids";
import { FiEdit, FiDelete, FiPrinter } from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert";
import { useEffect, useRef, useState } from "react";
import EditPatentData from "../../components/Modal/EditPatentData";
import { GetAllCitizen } from "../../api/CitizenData/getAllCitizen";
import { useAppSelector } from "../../app/hooks";
import { DeleteShcUser } from "../../api/ShcUser/deleteShcUser";
import { useAlert } from "react-alert";
import { ComponentToPrint } from "../../components/Print/PrintData";
import PrintSelectionModal from "../../components/Print/PrintSelectionModal";
import PrinterIocn from "../../assets/images/printer.png";
import { ComponentToMultPlePrint } from "../../components/MultipleCardPrints/MultiplePrint";
import SelectPostionForMultiplePrints from "../../components/MultipleCardPrints/SelectPostionForMultiplePrints";
import PatientDetailView from "../../components/Modal/PatientDetailView";
import moment from "moment";
export interface IPatientData {
  id: number;
  mob_no: string;
  email?: any;
  name: string;
  age?: any;
  sex: string;
  address: string;
  action_area: string;
  building: string;
  createdAt: Date;
  updatedAt: Date;
  diabetes_report?: any;
  flat_no?: any;
  plot_no?: any;
  street_no?: any;
  landmark?: any;
  date_of_birth?: any;
  blood_group?: any;
  high_bp?: any;
  alternative_mob_no?: any;
  emergency_contact?: any;
  profile_image?: any;
  diabetes_present?: any;
  card_no?: any;
}

const PatientData = () => {
  const componentRef = useRef(null);
  const componentMultiplePrintRef = useRef(null);
  // const handlePrintFunction = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const [ob, setOb] = useState<IPatientData>();
  const [printData, setPrintData] = useState<any>(null);
  const [open, setOpen] = useState(false);

  const [preview, setPreview] = useState<string | undefined>();
  const [patientData, setPatientData] = useState<IPatientData | any>([]);
  const { accessToken } = useAppSelector((state) => state.user);
  const { toggle } = useAppSelector((state) => state.toggle);

  const [cardPrintValue, setCardPrintValue] = useState<IPatientData>();

  //Selected Rows
  const [selectedRows, setselectedrows] = useState<any>(null);
  const [multiplePrintWithOutPostions, setMultipPrintWithOutPosition] =
    useState<any>(null);
  const [multiplePostions, setMultiplePostions] = useState<
    { t: number; l: number }[] | null
  >(null);

  // Row Dtls View
  const [isShoRowDetailsModal, setShowRowDetailModal] =
    useState<boolean>(false);

  const alert = useAlert();

  const deleteConstant = async (id: number) => {
    // const data = await RemoveDpMap(id)
    const data = await DeleteShcUser(accessToken, id);

    if (data?.data?.status === "success") {
      alert.success(data?.data?.message);
    } else {
      alert.success(data?.data?.message);
    }
    loadPatientData();
  };

  const imageTemplate = (args: any) => {
    if (!!args?.profile_image) {
      return (
        <img
          src={`https://api.cca.distronix.in:3443/${args?.profile_image}`}
          style={{
            width: 100,
            height: 100,
            borderRadius: "50%",
          }}
        />
      );
    } else {
      return null;
    }
  };

  const editConstant = (args: any) => {
    setOb(args);
    setPreview(args?.logo);
    setOpen(true);
  };

  const editTemplate = (args: any) => {
    return (
      <FiEdit
        style={{ cursor: "pointer" }}
        onClick={() => editConstant(args)}
      />
    );
  };
  const printTemplate = (args: any) => {
    return (
      <FiPrinter
        style={{ cursor: "pointer" }}
        onClick={() => {
          setCardPrintValue(args);
        }}
      />
    );
  };
  const deleteTemplate = (args: any) => {
    return (
      <FiDelete
        style={{ cursor: "pointer" }}
        onClick={() =>
          confirmAlert({
            title: "Confirm to Remove",
            message: "Are you sure to remove this Data?",
            buttons: [
              {
                label: "Yes",
                onClick: async () => {
                  deleteConstant(args?.id);
                },
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          })
        }
      />
    );
  };
  const filterOption = {
    ignoreAccent: true,
    type: "Excel",
  };
  const actionComplete = (args: any) => {
    if (args.requestType === "filtering") {
    }
  };

  const toolbarOptions = ["Search", "PdfExport", "ExcelExport"]; //"PdfExport",  "PdfExport",
  const toolbarClick = (args: any) => {
    if (grid) {
      if (args.item.id.includes("pdfexport")) {
        const Rows = grid?.getSelectedRecords();

        (grid.columns[1] as Column).width = 90;

        (grid.columns[2] as Column).width = 100;

        (grid.columns[3] as Column).visible = false;
        (grid.columns[4] as Column).width = 140;
        (grid.columns[5] as Column).width = 100;
        (grid.columns[6] as Column).width = 100;
        (grid.columns[6] as Column).headerText = "Alt. No.";
        (grid.columns[7] as Column).width = 100;
        (grid.columns[7] as Column).headerText = "Emg. No.";
        (grid.columns[8] as Column).width = 70;
        (grid.columns[8] as Column).headerText = "Gender";

        (grid.columns[9] as Column).width = 80;
        (grid.columns[9] as Column).headerText = "DOB";

        (grid.columns[10] as Column).width = 60;
        (grid.columns[10] as Column).headerText = "BG";

        (grid.columns[11] as Column).width = 80;
        (grid.columns[11] as Column).headerText = "High BP";

        (grid.columns[12] as Column).width = 80;
        (grid.columns[12] as Column).headerText = "Diabetes";

        // (grid.columns[11] as Column).visible = false;
        // (grid.columns[12] as Column).visible = false;
        (grid.columns[13] as Column).visible = false;
        (grid.columns[14] as Column).visible = false;

        // call pdf Export
        if (Rows.length > 0) {
          // alert("A maximum of 8 prints can be done in a single go.");

          grid.pdfExport({
            dataSource: Rows,
            fileName: "Patient-Data.pdf",
            pageOrientation: "Landscape",
          });
        } else {
          grid.pdfExport({
            fileName: "Patient-Data.pdf",
            pageOrientation: "Landscape",
          });
        }
      } else if (args.item.id.includes("excelexport")) {
        const Rows = grid?.getSelectedRecords();
        if (Rows.length > 0) {
          // alert("A maximum of 8 prints can be done in a single go.");
          grid.excelExport({
            dataSource: Rows,
            fileName: "Patient-Data.xlsx",
          });
        } else {
          grid.excelExport();
        }
      } else if (args.item.id.includes("Add New Data")) {
        //  addConstant()
      }
    }
  };

  let grid: GridComponent | null;

  const loadPatientData = async () => {
    const data = await GetAllCitizen(accessToken);
    if (data.status === 200) {
      const modifiedData = data?.data?.data?.map(
        (item: any, index: number) => ({
          ...item,
          idx: index + 1,
          date_of_birth: item?.date_of_birth
            ? moment(item?.date_of_birth).format("DD/MM/YYYY")
            : "Not Provided",
        })
      );
      setPatientData(modifiedData);
    }
    // console.log(data?.data?.data);
  };

  useEffect(() => {
    loadPatientData();
  }, []);
  const selectPositionHanlder = (t: number, l: number) => {
    const pValues = { t, l };
    setPrintData({ ...cardPrintValue, position: pValues });
  };
  const onCloseHandler = () => {
    setCardPrintValue(undefined);
  };

  // for Checkbox and multiple card_print
  const settings: SelectionSettingsModel = {
    type: "Multiple",
  };
  const getselectedRowsData = () => {
    const Rows = grid?.getSelectedRecords();
    if (Rows) {
      if (Rows?.length === 0) {
        // alert("A maximum of 8 prints can be done in a single go.");
        alert.info("Kindly pick the desired rows to print!");
      } else {
        // direct print if number of data is greater then 8 else allow user to select position for each item
        if (Rows?.length < 8 && Rows?.length > 0) {
          setselectedrows(Rows);
        } else {
          setMultiplePostions(null);
          setMultipPrintWithOutPosition(Rows);
        }
      }
    }
  };

  const _selectMuliplePrintWithPostionHandler = (
    args: { t: number; l: number }[]
  ) => {
    setMultiplePostions(args);
    setMultipPrintWithOutPosition(selectedRows);
    setselectedrows(null);
  };

  const patientDataView = (args: any) => {
    return (
      <div
        style={{ textDecorationColor: "#1d4ed8" }}
        className=" cursor-pointer underline"
        onClick={() => {
          setShowRowDetailModal(true);
          setOb(args);
        }}
      >
        {args?.name}
      </div>
    );
  };

  return (
    <>
      {/* <PrintDesign /> */}
      {/* For Single card postion choose  start */}
      {cardPrintValue && (
        // For Select card position
        <PrintSelectionModal
          selectPositionHanlder={selectPositionHanlder}
          onCloseHandler={onCloseHandler}
        />
      )}
      <div style={{ display: "none", visibility: "hidden" }}>
        <ComponentToPrint ref={componentRef} value={printData} />
      </div>

      {/* For Single card postion choose  end */}

      {/* For multiple card postion choose  start */}
      {!!selectedRows && (
        <SelectPostionForMultiplePrints
          onCloseHandler={_selectMuliplePrintWithPostionHandler}
          selectedRowsValue={selectedRows}
        />
      )}
      {/* For multiple card print  */}
      {multiplePrintWithOutPostions && (
        <div style={{ display: "none", visibility: "hidden" }}>
          <ComponentToMultPlePrint
            ref={componentMultiplePrintRef}
            value={multiplePrintWithOutPostions}
            postions={multiplePostions}
          />
        </div>
      )}

      {/* For multiple card postion choose  End */}

      <EditPatentData
        open={open}
        setOpen={setOpen}
        ob={ob}
        // preview={ob?.profile_image}
        // setPreview={setPreview}
        loadPatientData={loadPatientData}
      />
      {/* Row Detail View */}
      <PatientDetailView
        data={ob}
        open={isShoRowDetailsModal}
        onClose={() => setShowRowDetailModal(false)}
      />

      <div
        // className={`h-5/6 ml-${toggle ? "3": "10"} w-${toggle ? "1/12": "1/12"} sm:ml-${toggle ? "7": "20"} w-${toggle ? "11/12": "9/12"}`}
        //  className={`h-5/6 ml-${toggle ? "3": "10"} mt-10 w-${toggle ? "11/12": "9/12"}`}
        style={{
          marginLeft: toggle ? "1%" : "2%",
          marginTop: "1%",
          height: "80%",
          width: toggle ? "98%" : "96%",
        }}
      >
        <div className="flex justify-between">
          <h4 style={{ paddingTop: 10 }}>Master Data/Patient Master Data</h4>

          <button
            onClick={getselectedRowsData}
            className={`flex space-x-2 items-center  `}
          >
            <img src={PrinterIocn} alt="printterIcon" className="w-8 h-8" />
            <p className={`select-none text-lg font-semibold`}>
              Selected Print
            </p>
          </button>
        </div>

        <GridComponent
          dataSource={patientData}
          ref={(g) => (grid = g)}
          height="100%"
          width="100%"
          toolbar={toolbarOptions}
          allowPaging={true}
          allowFiltering={true}
          allowResizing={true}
          //@ts-ignore
          filterSettings={filterOption}
          toolbarClick={toolbarClick}
          allowSorting={true}
          allowPdfExport={true}
          allowExcelExport={true}
          enableHover={false}
          actionComplete={actionComplete}
          // allowTextWrap={true}
          selectionSettings={settings}
        >
          <ColumnsDirective>
            <ColumnDirective field="Checkbox" type="checkbox" width={60} />
            <ColumnDirective
              field="idx" //@ts-ignore
              textAlign="center"
              headerText="Sl No."
              width="140"
            />
            <ColumnDirective
              field="card_no" //@ts-ignore
              textAlign="center"
              headerText="Card No"
              width="140"
            />
            <ColumnDirective
              // field="logo"
              headerText="Profile Image"
              width="140"
              template={imageTemplate}
              //@ts-ignore
              textAlign="center"
              allowFiltering={false}
              allowSorting={false}
            />
            <ColumnDirective
              field="name"
              headerText="Name"
              width="140" //@ts-ignore
              textAlign="left"
              template={patientDataView}
            />
            <ColumnDirective
              field="mob_no"
              headerText="Phone No."
              width="150"
              //@ts-ignore
              textAlign="left"
            />

            <ColumnDirective
              field="alternative_mob_no"
              headerText="Alternate Contact No."
              width="170"
              //@ts-ignore
              textAlign="left"
            />
            <ColumnDirective
              field="emergency_contact"
              headerText="Emergency Contact No."
              width="170"
              //@ts-ignore
              textAlign="left"
            />
            <ColumnDirective
              field="sex"
              headerText="Gender"
              width="140" //@ts-ignore
              textAlign="left"
            />
            {/* <ColumnDirective
              field="email"
              headerText="Email"
              width="160" //@ts-ignore
              textAlign="left"
            /> */}
            <ColumnDirective
              field="date_of_birth"
              headerText="Date of Birth"
              width="160"
            />
            <ColumnDirective
              field="blood_group"
              headerText="Blood Group"
              width="160" //@ts-ignore
              textAlign="left"
            />
            <ColumnDirective
              field="high_bp"
              headerText="High BP"
              width="160" //@ts-ignore
              textAlign="left"
              template={(args: any) => {
                return <div>{args?.high_bp === true ? "Yes" : "No"}</div>;
              }}
            />
            <ColumnDirective
              field="diabetes_present"
              headerText="Diabetes Present"
              width="160" //@ts-ignore
              textAlign="left"
              template={(args: any) => {
                return (
                  <div>{args?.diabetes_present === true ? "Yes" : "No"}</div>
                );
              }}
            />
            {/* <ColumnDirective
              field="address"
              headerText="Address"
              width="160" //@ts-ignore
              textAlign="left"
            /> */}
            {/* <ColumnDirective
              headerText="Print"
              //@ts-ignore
              textAlign="left"
              width="100"
              template={printTemplate}
            /> */}

            <ColumnDirective
              headerText="Edit"
              //@ts-ignore
              textAlign="left"
              width="80"
              template={editTemplate}
            />
            <ColumnDirective
              headerText="Delete"
              //@ts-ignore
              textAlign="left"
              width="120"
              template={deleteTemplate}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Filter,
              Sort,
              Toolbar,
              PdfExport,
              Resize,
              ExcelExport,
            ]}
          />
        </GridComponent>
      </div>
    </>
  );
};

export default PatientData;
