import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Filter,
  Toolbar,
  PdfExport,
  ExcelExport,
  Sort,
  Resize,
} from "@syncfusion/ej2-react-grids";

import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";

export interface flatenData {
  sl_no: number;
  id: number;
  name: string;
  mob_no: string;
  profile_image: string;
  transaction_id?: number;
  lat?: number;
  long?: number;
  count?: number;
  created_at?: string;
}

const PatientTransction = () => {
  const { transaction } = useAppSelector((state) => state.transaction);

  const [gridData, setGridData] = useState<flatenData[]>([]);
  const {toggle} = useAppSelector((state) => state.toggle);

  const imageTemplate = (args: any) => {
    return (
      <img
        src={args.logo}
        style={{
          width: 100,
          height: 100,
        }}
        alt=""
      />
    );
  };

  const filterOption = {
    ignoreAccent: true,
    type: "Excel",
  };
  const actionComplete = (args: any) => {
    if (args.requestType === "filtering") {
    }
  };

  const toolbarOptions = ["Search", "ExcelExport"]; //"PdfExport", 
  const toolbarClick = (args: any) => {
    if (grid) {
      if (args.item.id.includes("pdfexport")) {
        grid.pdfExport({
          pageSize: "A3",
          fileName: "Patient_transaction_data.pdf",
          theme: {
            header: {
              bold: true,
              // border: { color: '#64FA50' },
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 14,
            },
            record: {
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 12,
            },
          },
        });
      } else if (args.item.id.includes("excelexport")) {
        grid.excelExport();
      } else if (args.item.id.includes("Add New Data")) {
        //  addConstant()
      }
    }
  };

  let grid: any;

  useEffect(() => {
    const data = transaction.reduce((acc: any, curr) => {
      const newArr = curr.TransactionHistory.length>0
        ? curr.TransactionHistory.map((item) => {
            return {
              id: curr.id,
              name: curr.name,
              mob_no: curr.mob_no,
              profile_image: curr.profile_image,
              transaction_id: item.id,
              lat: item.lat,
              long: item.long,
              count: item.count,
              created_at: item.createdat,
            };
          })
        : [{ ...curr }];

      return [...acc, ...newArr];
    }, []);

    const serialData = data.map((item: any, index: number) => ({
      ...item,
      sl_no: index + 1,
    }));

    setGridData(serialData);
  }, []);

  return (
    <>
      <div
      // className={`h-5/6 ml-${toggle ? "7": "20"} w-${toggle ? "11/12": "9/12"}`}
      style={{
        marginLeft: toggle ? "1%" : "2%",
        marginTop: "1%",
        height: "80%",
        width: toggle ? "98%": "96%",
      }}
        
      >
        <h4 style={{ paddingTop: 10 }}>Master Data/Patient Transaction Data</h4>
        <GridComponent
          dataSource={gridData}
          ref={(g) => (grid = g)}
          height="100%"
          width="100%"
          toolbar={toolbarOptions}
          allowPaging={true}
          allowFiltering={true}
          allowResizing={true}
          //@ts-ignore
          filterSettings={filterOption}
          toolbarClick={toolbarClick}
          allowSorting={true}
          allowPdfExport={true}
          allowExcelExport={true}
          enableHover={false}
          actionComplete={actionComplete}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective field="sl_no" headerText="Sl No" width="100" />
            <ColumnDirective field="id" headerText="Patient Id" width="100" />
            {/* <ColumnDirective
              field="logo"
              headerText="Logo"
              width="140"
              template={imageTemplate}
              //@ts-ignore
              textAlign="center"
              allowFiltering={false}
              allowSorting={false}
            /> */}

            <ColumnDirective
              field="name"
              headerText="Patient Name"
              width="140" //@ts-ignore
              textAlign="center"
            />
            <ColumnDirective
              field="mob_no"
              headerText="Patient Mobile Number"
              width="140" //@ts-ignore
              textAlign="center"
            />
            <ColumnDirective
              field="lat"
              headerText="Latitude"
              width="140" //@ts-ignore
              textAlign="center"
            />
            <ColumnDirective
              field="long"
              headerText="Longitude"
              width="140" //@ts-ignore
              textAlign="center"
            />
            <ColumnDirective
              field="count"
              headerText="Transaction Count"
              width="140"
              //@ts-ignore
              textAlign="center"
            />
            {/* <ColumnDirective
              field="count"
              headerText="Transaction Type"
              width="140"
              //@ts-ignore
              textAlign="center"
            /> */}
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Filter,
              Sort,
              Toolbar,
              PdfExport,
              ExcelExport,
              Resize,
            ]}
          />
        </GridComponent>
      </div>
    </>
  );
};

export default PatientTransction;
