import axios from "axios";

export const getDashBoardKPI = ({
  key,
  startDate,
  endDate,
}: {
  key: string;
  startDate: string;
  endDate: string;
}) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://api.cca.distronix.in:3443/caller/getKpiDatewise?start_date=${startDate}T00:00:00.000Z&end_date=${endDate}T23:59:59.999Z`,
    headers: {
      authorization: key,
    },
  };

  const res = axios
    //@ts-ignore
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return res;
};
