import axios from "axios";
import { useState, useEffect } from "react";
import { useAppSelector } from "../app/hooks";


const useGet = (api: any, dependency?: any[]) => {
    const { accessToken } = useAppSelector(s => s.user)
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        const url = `${api}`;
        const get = async () => {
            try {
                const res = await axios.get(url, {
                    headers: {
                        Authorization: `${accessToken}`,
                    }
                })
                // @ts-ignore
                setData(res.data.data)
                // @ts-ignore
                setMessage(res.data?.msg)
                setError("")
                setLoading(false)
            }
            catch (error) {
                // console.log(error)
                setData(0);
                // @ts-ignore
                setError(error?.message)
                setMessage("")
            }
        }

        get();
    }, dependency ? dependency : []);

    return { data, message, loading, error };
};

export default useGet; 
