import axios, {AxiosError, AxiosRequestConfig} from 'axios';


export const GetAllCitizen = async (key:string) => {

   

        const config: AxiosRequestConfig = {
            method: 'get',
            url: 'https://api.cca.distronix.in:3443/caller/shcUser/getAll',
            headers: { 
              authorization: key
            },
        
        }
     const result = axios(config).then((e:any)=> e).catch((e:any)=>{
       e as AxiosError
       return e
     }) 
     return result  
    }
  
