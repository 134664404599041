import { Field, FormikErrors } from "formik";
import React from "react";

type AppProps = {
  options?: string[];
  name: string;
  label: string;
  asterisk?: boolean;
  value?: string | number;
  error?: FormikErrors<{}> | undefined;
};

const DropDown: React.FC<{ props: AppProps }> = ({ props }) => {
  let itemOptions = props.options?.map((item: any) => (
    <option value={item?.split("+")[1]}>{item?.split("+", 1)}</option>
  ));

  return (
    <div className="relative flex flex-col flex-grow">
      <label
        className={`font-normal text-sm tracking-wide cursor-pointer ${
          props.error && "text-[#FF0000]"
        }`}
      >
        {props.label}{" "}
        {props.asterisk && <span className="text-red-600 -mt-4">*</span>}
      </label>

      <>
        <Field
          name={props.name}
          as="select"
          className={`w-full  border border-gray-300  ${
            props.error && "border-[#FF0000] text-[#FF0000]"
          } cursor-pointer bg-white rounded p-2 py-3 focus:outline-none text-sm`}
        >
          <option value={""}>
            {props?.value ? props?.value : "Select Option"}
          </option>
          {itemOptions}
        </Field>
        {props.error && (
          <div className="text-[#FF0000] text-xs">
            {/* @ts-ignore */}
            {props.error}
          </div>
        )}
      </>
    </div>
  );
};

export default DropDown;
