import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import InputField from "../InputField/InputField";
import { IPatientData } from "../../pages/MasterData/PatientData";
import moment from "moment";
import { useAlert } from "react-alert";
import { useAppSelector } from "../../app/hooks";
import { EditShcUser } from "../../api/ShcUser/editShcUser";
import DropDown from "../Dropdown";
import {
  BloodGroupGenerator,
  BloodGroupShowFormat,
  FindYesNo,
} from "../../util/HelpperFun";
import toast, { Toaster } from "react-hot-toast";

const EditPatentData: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ob: IPatientData | undefined;
  // preview: string | undefined;
  // setPreview: React.Dispatch<React.SetStateAction<string | undefined>>;
  loadPatientData: () => Promise<void>;
}> = ({ open, setOpen, ob, loadPatientData }) => {
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState<any | null>(null);
  const [base64, setBase64] = useState("");
  const [preview, setPreview] = useState<string | undefined>();
  const alert = useAlert();
  const [isLoading, setLoading] = useState(false);

  const { accessToken } = useAppSelector((s) => s.user);

  const handleImageChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
    ConvertFileToBase64(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };

  const ConvertFileToBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const base64String = event.target.result;
      setBase64(base64String);
    };
    reader.readAsDataURL(file);
  };
  const submitHandler = async (values: any) => {
    setLoading(true);
    let isBloodGroup = {};
    let isDateOfBirth = {};

    if (BloodGroupGenerator(values?.bloodGroup) !== "Select") {
      isBloodGroup = { blood_group: BloodGroupGenerator(values?.bloodGroup) };
    }
    if (values?.Dob !== "Invalid date") {
      isDateOfBirth = { date_of_birth: values?.Dob };
    }

    let withImage = {
      name: values.name,
      alternative_mob_no: values?.alternateNumber || "",
      emergency_contact: values?.emergencyNumber || "",
      ...isBloodGroup,
      ...isDateOfBirth,
      sex:
        values?.gender === "Male"
          ? "M"
          : values?.gender === "Female"
          ? "F"
          : "O",
      diabetes_present: values?.Diabetes === "Yes" ? true : false,
      high_bp: values?.BP === "Yes" ? true : false,
      contents: base64 || "",
      file_name: selectedFile?.name || "",
      profile_image: true,
      mob_no: values?.number,
    };
    let withOutImage = {
      name: values?.name || "",
      alternative_mob_no: values?.alternateNumber || "",
      emergency_contact: values?.emergencyNumber || "",
      ...isBloodGroup,
      ...isDateOfBirth,
      sex:
        values?.gender === "Male"
          ? "M"
          : values?.gender === "Female"
          ? "F"
          : "O",
      diabetes_present: values?.Diabetes === "Yes" ? true : false,
      high_bp: values?.BP === "Yes" ? true : false,
    };

    let response = await EditShcUser(
      accessToken,
      selectedFile ? withImage : withOutImage,
      ob?.id
    );

    if (response?.data?.status === "success") {
      setLoading(false);
      setOpen(false);
      alert.success("Patient Data Updated Successfully");
      loadPatientData();
    } else {
      setLoading(false);
      setOpen(false);
      alert.error("Something went wrong");
    }
    // }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    number: Yup.number().min(10, "Phone No. not be less then 10").required(),
    // alternateNumber: Yup.number()
    //   .min(10, "Phone No. not be less then 10")
    //   .required(),
    image_file: Yup.string().required(),
  });

  useEffect(() => {
    setSelectedFile(null);
  }, [ob]);

  return (
    <Formik
      initialValues={{
        // ShcId: "",
        name: ob?.name,
        alternateNumber:
          ob?.alternative_mob_no === null ? "" : ob?.alternative_mob_no.trim(),
        emergencyNumber: ob?.emergency_contact,
        bloodGroup: BloodGroupShowFormat(ob?.blood_group),
        BP: ob?.high_bp,
        Diabetes: ob?.diabetes_present,
        Dob: moment(ob?.date_of_birth, "DD/MM/YYYY").format("YYYY-MM-DD"),
        gender: ob?.sex === "M" ? "Male" : ob?.sex === "F" ? "Female" : "Other",
        number: ob?.mob_no,
        image_file: ob?.profile_image,
      }}
      // validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          submitHandler(values);
        } catch (error: any) {}
      }}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          initialValues,
          setValues,
        } = props;
        return (
          <Modal
            size="tiny"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            dimmer={"blurring"}
          >
            <Modal.Header>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Icon name="setting" color="green" />
                Edit Patient Data
              </p>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 15,
                  }}
                >
                  {/* Image uploading */}
                  <div className=" pt-1 flex justify-center items-center space-x-2 px-4">
                    {selectedFile !== null ? (
                      <div
                        style={{
                          height: "120px",
                          width: " 180px",
                          border: "1px solid gray",
                        }}
                        className=" rounded-lg shadow-lg p-1"
                      >
                        <img
                          src={preview}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </div>
                    ) : (
                      values?.image_file && (
                        <div
                          style={{
                            height: "120px",
                            width: " 180px",
                            border: "1px solid gray",
                          }}
                          className=" rounded-lg shadow-lg p-1"
                        >
                          <img
                            src={`https://api.cca.distronix.in:3443/${values?.image_file}`}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        </div>
                      )
                    )}
                    <input
                      id="file"
                      type="file"
                      style={{ display: "none" }}
                      ref={inputRef}
                      onChange={(e) => {
                        handleImageChange(e);
                        setValues({ ...values, image_file: "profile_imgae" });
                      }}
                    />

                    <Button
                      id="file"
                      onClick={
                        //@ts-ignore
                        () => inputRef.current.click()
                      }
                      className=" shadow-xl rounded-lg"
                    >
                      Add Photo <span className="text-red-600 -mt-4">*</span>
                      <Icon
                        //@ts-ignore
                        name="cloud upload right"
                      />
                    </Button>
                  </div>
                  <div
                    className=" grid grid-cols-2"
                    style={{
                      gap: 15,
                    }}
                  >
                    <InputField
                      label="Name"
                      props={props}
                      name={Object.keys(initialValues)[0]}
                      value={values.name}
                      error={errors.name}
                      touched={touched.name}
                      asterisk={true}
                    />
                    <InputField
                      label="Phone No."
                      props={props}
                      name={Object.keys(initialValues)[8]}
                      value={values.number}
                      error={errors.number}
                      touched={touched.number}
                      asterisk={true}
                    />
                    <InputField
                      label="Alternate No."
                      props={props}
                      name={Object.keys(initialValues)[1]}
                      value={values.alternateNumber}
                      error={errors.alternateNumber}
                      touched={touched.alternateNumber}
                    />

                    <InputField
                      label="Emergency No."
                      props={props}
                      name={Object.keys(initialValues)[2]}
                      value={values.emergencyNumber}
                      error={errors.emergencyNumber}
                      touched={touched.emergencyNumber}
                    />

                    <DropDown
                      props={{
                        options: [
                          "A Positive",
                          "A Negative",
                          "B Positive",
                          "B Negative",
                          "O Positive",
                          "O Negative",
                          "AB Positive",
                          "AB Negative",
                        ],
                        name: "bloodGroup",
                        label: "Blood Group ",
                        asterisk: undefined,
                        value: values?.bloodGroup,
                        error: undefined,
                      }}
                    />

                    <DropDown
                      props={{
                        options: ["Yes", "No"],
                        name: "BP",
                        label: "BP",
                        asterisk: undefined,
                        value: FindYesNo(values?.BP),
                        error: undefined,
                      }}
                    />
                    <DropDown
                      props={{
                        options: ["Yes", "No"],
                        name: "Diabetes",
                        label: "Diabetes",
                        asterisk: undefined,
                        value: FindYesNo(values?.Diabetes),
                        error: undefined,
                      }}
                    />

                    <InputField
                      label="Date Of Birth"
                      props={props}
                      name={Object.keys(initialValues)[6]}
                      value={values.Dob}
                      error={errors.Dob}
                      touched={touched.Dob}
                    />
                    <DropDown
                      props={{
                        options: ["Male", "Female", "Others"],
                        name: "gender",
                        label: "Gender",
                        asterisk: true,
                        value: values?.gender,
                        error: undefined,
                      }}
                    />
                  </div>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <div className="flex justify-end space-x-2">
                <Button negative onClick={() => setOpen(false)}>
                  Close <Icon name="close" />
                </Button>
                {isLoading ? (
                  <div className="w-24  items-center">
                    <div className="mx-auto w-8 h-8 border-t-2 border-blue-500 border-solid rounded-full animate-spin"></div>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit} style={{ all: "initial" }}>
                    <Button
                      // onClick={() => setOpen(false)}
                      primary
                      type="submit"
                      // disabled={isSubmitting}
                    >
                      Edit <Icon name="edit" />
                    </Button>
                  </form>
                )}
              </div>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditPatentData;
