import { createSlice, PayloadAction } from "@reduxjs/toolkit"


interface toggleState{

    toggle:boolean;
}

const initialState:toggleState = {
      toggle:false,
}

const toggleSlice= createSlice({
    name:'toggle',
    initialState,
    reducers:{

        setToggle:(state) => {
           
             state.toggle ?  state.toggle = false: state.toggle = true
           
        }

    }

})

export const {setToggle} = toggleSlice.actions

export default toggleSlice.reducer