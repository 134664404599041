import axios, { AxiosError, AxiosRequestConfig } from "axios";

export const CitizenTransactionById = async ({
  key,
  patient_id = 58454,
}: {
  key: string;
  patient_id: any;
}) => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: `https://api.cca.distronix.in:3443/caller/transactionHistory/citizen/${patient_id}`,
    headers: {
      authorization: key,
    },
  };
  const result = axios(config)
    .then((e: any) => e)
    .catch((e: any) => {
      e as AxiosError;
      return e;
    });
  return result;
};
