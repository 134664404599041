import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Filter,
  Toolbar,
  PdfExport,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { FiEdit, FiDelete } from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert";
import { clinicData } from "../../assets/mockData/clinicData";
import { useEffect, useState } from "react";
import EditClinicData from "../../components/Modal/EditClinicData";
import { useAppSelector } from "../../app/hooks";
// import { medicalCenter } from "../../api/medicalcenter";
import useGet from "../../api/useGet";
import { Icon } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import AddMedicalCenter from "./UploadDocument";
import Modal from "../Dashbord/Modal";
import Snackbar from "../../components/Snackbar";
import PositionedSnackbar from "../../components/Snackbar";

const MedicalCenter = () => {
  const [ob, setOb] = useState();
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState<string | undefined>();
  const { accessToken } = useAppSelector((s) => s.user);
  const [open1, setOpen1] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [sucMsg, setSucMsg] = useState("");

  const deleteConstant = async (args: any) => {
    await fetch(
      `https://api.cca.distronix.in:3443/caller/medicalCenter/deleteOne/${args}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `${accessToken}`,
        },
      }
    )
      .then((response) => {
        setOpen1(!open1);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          window.location.reload();
          setErrMsg("");
          setSucMsg(data.msg);
          setOpen(false);
        } else {
          setSucMsg("");
          setErrMsg(data.msg);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   window.location.reload()
  // }, [open])

  const { data } = useGet(
    "https://api.cca.distronix.in:3443/caller/medicalCenter/getAll"
  );

  const editConstant = (args: any) => {
    setOb(args);
    setPreview(args?.logo);
    setOpen(true);
  };

  const editTemplate = (args: any) => {
    return (
      <FiEdit
        style={{ cursor: "pointer" }}
        onClick={() => editConstant(args)}
      />
    );
  };

  const deleteTemplate = (args: any) => {
    return (
      <FiDelete
        style={{ cursor: "pointer" }}
        onClick={() =>
          confirmAlert({
            title: "Confirm to Remove",
            message: "Are you sure to remove this Data?",
            buttons: [
              {
                label: "Yes",
                onClick: async () => {
                  deleteConstant(args.id);
                },
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          })
        }
      />
    );
  };

  const filterOption = {
    ignoreAccent: true,
    type: "Excel",
  };
  const actionComplete = (args: any) => {
    if (args.requestType === "filtering") {
    }
  };

  const toolbarOptions = ["Search", "PdfExport", "ExcelExport"]; //, "PdfExport"
  const toolbarClick = (args: any) => {
    if (grid) {
      if (args.item.id.includes("pdfexport")) {
        grid.columns[7].visible = false;
        grid.columns[6].visible = false;
        grid.pdfExport({
          pageSize: "A3",
          fileName: "Clinic-Data.pdf",
          theme: {
            header: {
              bold: true,
              // border: { color: '#64FA50' },
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 14,
            },
            record: {
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 12,
            },
          },
        });
      } else if (args.item.id.includes("excelexport")) {
        grid.columns[7].visible = false;
        grid.columns[6].visible = false;
        grid.excelExport();
      } else if (args.item.id.includes("Add New Data")) {
        //  addConstant()
      }
    }
  };

  let grid: any;

  const handleClose = () => {
    setOpen1(!open1);
  };

  return (
    <>
      <PositionedSnackbar
        open={open1}
        message_err={errMsg}
        message_suc={sucMsg}
        handleClose={handleClose}
      />
      <EditClinicData
        open={open}
        setOpen={setOpen}
        ob={ob}
        preview={preview}
        setPreview={setPreview}
      />
      <div
        style={{
          height: "600px",
          minWidth: "100%",
          paddingLeft: "1%",
          paddingRight: "1%",
          textAlign: "center",
        }}
      >
        <h2 style={{ paddingTop: 10 }}>Authorised Center Master Data</h2>
        <Modal />
        <GridComponent
          dataSource={data}
          ref={(g) => (grid = g)}
          height="100%"
          width="100%"
          toolbar={toolbarOptions}
          allowPaging={true}
          allowFiltering={true}
          allowResizing={true}
          //@ts-ignore
          filterSettings={filterOption}
          toolbarClick={toolbarClick}
          allowSorting={true}
          allowPdfExport={true}
          allowExcelExport={true}
          enableHover={false}
          actionComplete={actionComplete}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              headerText="Sl No"
              width="100"
              //@ts-ignore
              textAlign="left"
            />
            <ColumnDirective
              field="name"
              headerText="Name"
              width="140" //@ts-ignore
              textAlign="left"
            />

            <ColumnDirective
              field="addr"
              headerText="Address"
              width="160"
              //@ts-ignore
              textAlign="left"
            />
            <ColumnDirective
              field="cont_no"
              headerText="Phone No."
              width="140"
              //@ts-ignore
              textAlign="left"
            />
            <ColumnDirective
              field="lat"
              headerText="Latitude"
              width="140" //@ts-ignore
              textAlign="left"
            />
            <ColumnDirective
              field="long"
              headerText="Longitude"
              width="140" //@ts-ignore
              textAlign="left"
            />
            <ColumnDirective
              headerText="Update"
              //@ts-ignore
              textAlign="left"
              width="120"
              template={editTemplate}
            />
            <ColumnDirective
              headerText="Delete"
              //@ts-ignore
              textAlign="left"
              width="120"
              template={deleteTemplate}
            />
          </ColumnsDirective>
          <Inject
            services={[Page, Filter, Sort, Toolbar, PdfExport, ExcelExport]}
          />
        </GridComponent>
      </div>
    </>
  );
};

export default MedicalCenter;
