import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import InputField from "../InputField/InputField";

const FormModal: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {

  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState<string>()

  const handleImageChange = (e: any) => {

    setSelectedFile(e.target.files[0]);

  };

  useEffect(() => {
    if (!selectedFile) {
        setPreview(undefined)
        return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
}, [selectedFile])

  return (
    <Formik
      initialValues={{
        name: "",
        number: null,
        lat: null,
        long: null,
        address: "",
      }}
      onSubmit={(values, actions) => {
        console.log(values);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        number: Yup.number()
          .required("Required")
          .typeError("Invalid Contact Number"),
        lat: Yup.number().required("Required").typeError("Invalid Latitude"),
        long: Yup.number().required("Required").typeError("Invalid Longitude"),
        address: Yup.string().required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          initialValues,
        } = props;
        console.log(errors);
        return (
          <Modal
            size="tiny"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            dimmer={"blurring"}
          >
            <Modal.Header>
              {" "}
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Icon name="hospital" color="green" />
                Add New Health Center
              </p>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 10,
                  }}
                >
                  <div 
                  style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:5
                  }}>
                   <div
                     style={{
                      height: "100px",
                      width:" 200px",
                      borderLeft: "1px solid gray",
                      borderRight: "1px solid gray",
                      borderTop:" 5px solid gray",
                      borderBottom: "5px solid gray",
                     }}
                   >
                 {
                   !!preview ? <img
                   src={preview}
                   style={{
                      height:'100%',
                      width:'100%',
                   }}
                />: <div style={{
                  width: "100%",
                  marginTop: "20px",
                  marginLeft:'10px',
                }}>Please select an Image for Preview</div>
                 }  
                  </div> 
                  <input
                    id="file"
                    type="file"
                    style={{ display: "none" }}
                    ref={inputRef}
                    onChange={handleImageChange}
                  />

                  <Button
                    id="file"
                    onClick={
                      //@ts-ignore
                      () => inputRef.current.click()
                    }
                  >
                    Add Logo
                  </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 15,
                      width: "100%",
                    }}
                  >
                    <InputField
                      label="Center Name"
                      props={props}
                      name={Object.keys(initialValues)[0]}
                      value={values.name}
                      error={errors.name}
                      touched={touched.name}
                    />
                    <InputField
                      label="Contact Number"
                      props={props}
                      name={Object.keys(initialValues)[1]}
                      value={values.number}
                      error={errors.number}
                      touched={touched.number}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 15,
                      width: "100%",
                    }}
                  >
                    <InputField
                      label="Latitude"
                      props={props}
                      name={Object.keys(initialValues)[2]}
                      value={values.lat}
                      error={errors.lat}
                      touched={touched.lat}
                    />
                    <InputField
                      label="Longitude"
                      props={props}
                      name={Object.keys(initialValues)[3]}
                      value={values.long}
                      error={errors.long}
                      touched={touched.long}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 15,
                      width: "100%",
                    }}
                  >
                    <InputField
                      label="Address"
                      props={props}
                      name={Object.keys(initialValues)[4]}
                      value={values.address}
                      error={errors.address}
                      touched={touched.address}
                    />
                  </div>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={() => setOpen(false)}>
                Close <Icon name="close" />
              </Button>
              <form onSubmit={handleSubmit} style={{ all: "initial" }}>
                <Button
                  // onClick={() => setOpen(false)}
                  primary
                  type="submit"
                  // disabled={isSubmitting}
                >
                  ADD <Icon name="add" />
                </Button>
              </form>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default FormModal;
