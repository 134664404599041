import React, { useEffect, useState } from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import { IPatientData } from "../../pages/MasterData/PatientData";
import usersIcon from "../../assets/images/User.jpeg";
import moment from "moment";
import { useAppSelector } from "../../app/hooks";
import { CitizenTransactionById } from "../../api/CitizenData/getTranscationById";
import { Formik, Form } from "formik";
type Props = {
  data: IPatientData | any;
  onClose: () => void;
  open: boolean;
};

const PatientDetailView = ({ data, onClose, open }: Props) => {
  const { accessToken } = useAppSelector((state) => state.user);
  const [transactionList, setTransactionList] = useState([]);

  const ReadTransaction = async () => {
    const res = await CitizenTransactionById({
      key: accessToken,
      patient_id: data?.id,
    });
    setTransactionList(res?.data?.data);
  };
  useEffect(() => {
    ReadTransaction();
  }, [data]);

  return (
    <Modal
      size="large"
      open={open}
      onClose={() => onClose()}
      onOpen={() => {}}
      dimmer={"blurring"}
      style={{ backgroundColor: "#f3f4f6" }}
    >
      <div className="p-4 px-6 grid grid-cols-4 gap-4 ">
        <div className=" bg-white rounded-md shadow-md items-center justify-center flex p-4 my-4">
          <div>
            <img
              src={
                data?.profile_image
                  ? `https://api.cca.distronix.in:3443/${data?.profile_image}`
                  : usersIcon
              }
              className="w-32 h-32 rounded-full mx-auto shadow-md"
            />
            <div className=" h-8 w-full rounded mt-4 text-xl font-semibold truncate">
              {data?.name}
            </div>
            <div className=" w-full rounded mt-2 text-base">
              SHC No. - <span className="font-semibold"> {data?.card_no}</span>
            </div>
            <div className="w-full rounded mt-2 text-base">
              Medical Center - <span className="font-semibold"> {}</span>
            </div>
          </div>
        </div>
        <div className="col-span-3 bg-white grid grid-cols-3 rounded-md shadow-md  p-4 my-4 ">
          <div>
            <h1 className=" text-xl">Gender</h1>
            <h1 className=" text-base -mt-4">
              {data?.sex === "M"
                ? "MALE"
                : data?.sex === "F"
                ? "FEMALE"
                : "OTHER"}
            </h1>
          </div>
          <div>
            <h1 className=" text-xl">Age (Yr.)</h1>
            <h1 className=" text-base -mt-4">
              {moment().diff(data?.date_of_birth, "years")}
            </h1>
          </div>
          <div>
            <h1 className=" text-xl">Address</h1>
            <h1 className=" text-base -mt-4">{data?.address}</h1>
          </div>
          <div>
            <h1 className=" text-xl">Phone No.</h1>
            <h1 className=" text-base -mt-4">{data?.mob_no}</h1>
          </div>
          <div>
            <h1 className=" text-xl">Blood Group</h1>
            <h1 className=" text-base -mt-4">{data?.blood_group}</h1>
          </div>
          <div>
            <h1 className=" text-xl">Emergency No.</h1>
            <h1 className=" text-base -mt-4">{data?.emergency_contact}</h1>
          </div>
          <div>
            <h1 className=" text-xl">High BP</h1>
            <h1 className=" text-base -mt-4">
              {data?.high_bp === true ? "Yes" : "No"}
            </h1>
          </div>
          <div>
            <h1 className=" text-xl">Diabetes</h1>
            <h1 className=" text-base -mt-4">
              {data?.diabetes_present === true ? "Yes" : "No"}
            </h1>
          </div>
          <div>
            <h1 className=" text-xl">Latest Prescription</h1>
            <h1 className=" text-base -mt-4">{data?.pdf}</h1>
          </div>
        </div>
      </div>

      {/* Last Transcations */}
      <div className=" bg-white rounded-md  shadow-md  p-4 m-6 -mt-4 ">
        <div className="">
          <h1 className="text-xl ">Transaction</h1>
        </div>

        <div
          className={`bg-white rounded-md grid grid-cols-3 py-2   ${
            transactionList.length === 0 ? "h-20" : "h-40"
          } overflow-x-hidden overflow-y-scroll`}
        >
          {/* Table Hader */}
          <div className="border border-black p-3 ">
            <h1 className="text-lg font-semibold">Canter Name</h1>
          </div>
          <div className="border border-black p-3 border-l-0 font-medium">
            <h1 className="text-lg font-semibold">Center Address </h1>
          </div>
          <div className="border border-black p-3 border-l-0 font-medium">
            <h1 className="text-lg font-semibold">Visit Date</h1>
          </div>

          {/* Table Row */}

          {transactionList?.map((item: any) => {
            return (
              <>
                <div className="border border-black p-3 border-t-0">
                  {item?.medical_center_name}
                </div>
                <div className="border border-black p-3 border-l-0 border-t-0">
                  {item?.medical_center_address}
                </div>
                <div className="border border-black p-3 border-l-0 border-t-0">
                  {moment(item?.visited_time).format("DD/MM/YYYY")}
                </div>
              </>
            );
          })}
        </div>
      </div>
{/* 
      <div className="w-60 m-3 mx-auto">
        <a
          href={`http://shc.singlepage.distronix.in/?accessToken=${accessToken}&lat=35.3434&long=35.3434`}
          target="_blank"
          className="text-center rounded-md cursor-pointer"
        >
          <div
            className="text-center p-4 rounded-md cursor-pointer "
            style={{ backgroundColor: "#b6c8ff" }}
          >
            Detailed Medical History
          </div>
        </a>
      </div> */}
    </Modal>
  );
};

export default PatientDetailView;
