import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import InputField from "../InputField/InputField";
import { GetAllRegistered } from "../../api/CardDataApi/getAllRegistered";
import { useAppSelector } from "../../app/hooks";
import { RegisterCard } from "../../api/CardDataApi/registerCard";
import { useAlert } from "react-alert";

const RegisterHSC: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {

   const {accessToken} = useAppSelector(state => state.user);
   const alert = useAlert()

  return (
    <Formik
      initialValues={{ID: "" }}
      onSubmit={async(values, actions) => {
        // console.log(values);
        const response = await RegisterCard(values.ID,accessToken);
        if(response?.data === 'success'){
          setOpen(false)
          alert.success(response?.data?.message)
        }
        else{
          setOpen(false)
          alert.success(response?.data?.message)
        }
        console.log(response);

      }}
      validationSchema={Yup.object().shape({

        ID: Yup.string().required("Required").max(10, "Must be 10 characters or less"),
 
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          initialValues,
        } = props;
        console.log(errors)
        return (
          
          <Modal
            size="tiny"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            dimmer={"blurring"}
          >
           
            <Modal.Header>
              {" "}
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <Icon name="" color="green" /> */}
                Register New SMART HEALTH CARD(SHC)
              </p>
            </Modal.Header>
           
            <Modal.Content scrolling>
              <Modal.Description>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 15,
                      width: "100%",
                    }}
                  >
                    <InputField
                      label="ID"
                      props={props}
                      name={Object.keys(initialValues)[0]}
                      value={values.ID}
                      error={errors.ID}
                      touched={touched.ID}
                    />
                  </div>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={() => setOpen(false)}>
                Close <Icon name="close" />
              </Button>
              <form onSubmit={handleSubmit} style={{all:'initial'}}>
              <Button
                // onClick={() => setOpen(false)}
                primary
                type="submit"
                // disabled={isSubmitting}
              >
                Register <Icon name="add" />
              </Button>
              </form>
            </Modal.Actions>
       
          </Modal>
         
        );
      }}
    </Formik>
  );
};

export default RegisterHSC;
