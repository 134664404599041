import "./styles.css";
import logo1 from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import rohitsir from "../../assets/images/user.svg";
import medical from "../../assets/images/medical-logo.png";
import { RiArrowDropDownLine, RiLogoutBoxRLine } from "react-icons/ri";
import { AiOutlineUserAdd } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";
import Popper from "@mui/material/Popper";
import React, { useState } from "react";
import { logout } from "../../features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RegisterUser from "../Modal/RegisterUser";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useAppDispatch();
  const { accessToken, userName } = useAppSelector((state) => state.user);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  return (
    <header
      style={{
        backgroundColor: "#03586A",
      }}
      className={"sticky shadow-md  z-50 w-full px-5 py-2 flex justify-between"}
    >
      <RegisterUser open={openModal} setOpen={setOpenModal} />

      <div className="flex flex-row items-center justify-center">
        <img src={logo2} className="h-7 object-contain sm:h-14" />
        <img src={logo1} className="h-7 object-contain sm:h-14" />
      </div>
      <div className="flex justify-center items-center gap-2 ml-5">
        <img
          src={medical}
          className="hidden h-9 object-contain sm:inline-flex"
        />
        <p className="text-white font-bold text-sm tracking-wider sm:text-3xl">
          NKDA SMART HEALTH CARD DASHBOARD
        </p>
      </div>

      <div className="flex items-center justify-center">
        {accessToken !== "" && (
          <>
            <img
              src={rohitsir}
              className="h-6 rounded-full object-contain sm:h-10"
            />
            <div>
              <p className="text-white  text-sm pl-2">{userName}</p>
            </div>
            <button aria-describedby={id} type="button" onClick={handleClick}>
              <RiArrowDropDownLine
                color="#ffffff"
                size={30}
                className="cursor-pointer"
              />
            </button>
          </>
        )}

        <Popper id={id} open={open} anchorEl={anchorEl}>
          <div
            className="h-[450px] w-40 text-white z-10  mt-2 sm:mt-4"
            style={{
              backgroundColor: "#03586A",
            }}
          >
            <p className="pl-2 pr-6 pt-1 text-sm">Admin</p>

            {/* <div
              className="cursor-pointer flex items-center justify-start border-t-2 border-gray-600 mt-2 p-2"
              onClick={() => setOpenModal(true)}
            >
              <AiOutlineUserAdd color="#89dee2" />
              <p className="text-white ml-3 text-sm">Add User</p>
            </div> */}
            <div
              className="cursor-pointer flex items-center justify-start border-t-2 border-gray-600 mt-2 p-2"
              onClick={() => dispatch(logout())}
            >
              <RiLogoutBoxRLine color="red" />
              <p className="text-white ml-3 text-sm">Logout</p>
            </div>
          </div>
        </Popper>
      </div>
    </header>
  );
};

export default Header;
