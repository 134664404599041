import axios, {AxiosError, AxiosRequestConfig} from 'axios';


export const DeleteShcUser = async (key:string,id:number|undefined) => {

   

        const config: AxiosRequestConfig = {
            method: 'DELETE',
            url: `https://api.cca.distronix.in:3443/caller/shcUser/deleteOne/${id}`,
            headers: { 
              authorization: key
            },
          
        }
     const result = axios(config).then((e:any)=> e).catch((e:any)=>{
       e as AxiosError
       return e
     }) 
     return result  
    }