import React from "react";
import { Modal } from "semantic-ui-react";

type Props = {
  selectPositionHanlder: (t: number, l: number) => void;
  onCloseHandler: () => void;
};

const PrintSelectionModal = (props: Props) => {
  const [isActive, setIsActive] = React.useState<number>(1);
  return (
    <Modal
      size="tiny"
      open={true}
      onClose={() => props?.onCloseHandler()}
      onOpen={() => true}
      dimmer={"blurring"}
    >
      <h1 className="text-center pt-2 -pb-2">Please select the position </h1>
      <div
        className="bg-white grid grid-cols-2 p-4 gap-4"
        style={{ height: "80vh" }}
      >
        <div
          onClick={() => {
            props?.onCloseHandler();
            setIsActive(1);
            props.selectPositionHanlder(0, 0);
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            isActive === 1 && "bg-blue-500"
          }`}
        >
          1
        </div>
        <div
          onClick={() => {
            props?.onCloseHandler();
            setIsActive(2);
            props.selectPositionHanlder(0, 1);
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            isActive === 2 && "bg-blue-500"
          }`}
        >
          2
        </div>
        <div
          onClick={() => {
            props?.onCloseHandler();
            setIsActive(3);
            props.selectPositionHanlder(1, 0);
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            isActive === 3 && "bg-blue-500"
          }`}
        >
          3
        </div>
        <div
          onClick={() => {
            props?.onCloseHandler();
            setIsActive(4);
            props.selectPositionHanlder(1, 1);
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            isActive === 4 && "bg-blue-500"
          }`}
        >
          4
        </div>
        <div
          onClick={() => {
            props?.onCloseHandler();
            setIsActive(5);
            props.selectPositionHanlder(2, 0);
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            isActive === 5 && "bg-blue-500"
          }`}
        >
          5
        </div>
        <div
          onClick={() => {
            props?.onCloseHandler();
            setIsActive(6);
            props.selectPositionHanlder(2, 1);
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            isActive === 6 && "bg-blue-500"
          }`}
        >
          6
        </div>
        <div
          onClick={() => {
            props?.onCloseHandler();
            setIsActive(7);
            props.selectPositionHanlder(3, 0);
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            isActive === 7 && "bg-blue-500"
          }`}
        >
          7
        </div>
        <div
          onClick={() => {
            props?.onCloseHandler();
            setIsActive(8);
            props.selectPositionHanlder(3, 1);
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            isActive === 8 && "bg-blue-500"
          }`}
        >
          8
        </div>
      </div>
    </Modal>
  );
};

export default PrintSelectionModal;
