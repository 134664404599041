import React, { useEffect, useRef, useState } from "react";
import NKDALogo from "../../assets/images/nkda1.jpg";
import User from "../../assets/images/User.jpeg";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

const DUMMYARRAY = [
  { t: 0, l: 0 },
  { t: 0, l: 1 },
  { t: 1, l: 0 },
  { t: 1, l: 1 },
  { t: 2, l: 0 },
  { t: 2, l: 1 },
  { t: 3, l: 0 },
  { t: 3, l: 1 },
];

export const ComponentToMultPlePrint = React.forwardRef(
  ({ value, postions }: any, ref) => {
    const componentRef = useRef(null);
    const handlePrintFunction = useReactToPrint({
      content: () => componentRef.current,
    });

    var postionCount = 0;

    useEffect(() => {
      value && handlePrintFunction();
    }, [value]);

    const textStyle = {
      fontSize: "14px",
      fontWeight: 800,
      lineHeight: "1rem",
      color: "#104053",
    };
    const normalTextStyle = {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "1rem",
      fontFamily: "inherit",
      color: "#347383",
    };

    return (
      //@ts-ignore
      <div ref={componentRef} className="grid grid-cols-2 gap-1 py-2">
        {/* Main container */}
        {!!postions
          ? DUMMYARRAY.map((item, index) => {
              // if postion match print card
              let isFound;
              postions.map((postion: any, index: number) => {
                if (postion.t === item?.t && postion?.l === item?.l) {
                  isFound = postion;
                  postionCount = postionCount + 1;
                }
              });
              if (isFound) {
                return (
                  <div
                    className="flex items-center justify-center "
                    style={{
                      width: "321.26px",
                      height: "202px",
                      marginLeft: "2.44rem",
                      marginRight: "2.44rem",
                      marginTop: "2.3rem",
                      marginBottom: "2.8rem",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className=" rounded-xl  border-[1px] shadow-lg overflow-hidden"
                      style={{
                        // top: 200,
                        // zIndex: 100,
                        // position: "absolute",
                        height: "321.26px",
                        width: "202px",
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "#000",
                        backgroundColor: "#ffffff",
                        transform: "rotate(90deg)",
                      }}
                    >
                      {/* Top Section */}
                      <div
                        className=" h-2/5 w-full shadow-2xl"
                        style={{
                          backgroundColor: "#7fb1be",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.6)",
                        }}
                      >
                        <div className=" w-full pt-2 flex -mb-6  items-center justify-center ">
                          <img
                            src={NKDALogo}
                            alt="NKDA logo"
                            style={{
                              height: "40px",
                              width: "50px",
                              objectFit: "fill",
                              backgroundColor: "#000",
                            }}
                          />
                        </div>
                        <h1
                          className="font-link"
                          style={{ fontSize: 15, textAlign: "center" }}
                        >
                          NKDA - Smart Health Card
                        </h1>
                      </div>
                      {/* Profile Image */}
                      <div className=" w-full  flex -mt-14  items-center justify-center bg-[#7fb1be]">
                        <img
                          src={
                            value[postionCount - 1]?.profile_image !== null
                              ? `https://api.cca.distronix.in:3443/${
                                  value[postionCount - 1]?.profile_image
                                }`
                              : User
                          }
                          alt="UserPhoto"
                          style={{
                            height: "90px",
                            width: "95px",
                            objectFit: "fill",
                            backgroundColor: "#000",
                          }}
                        />
                      </div>

                      {/* Middle Sction */}
                      <div>
                        <div
                          style={{
                            paddingRight: "0.6rem",
                            paddingLeft: "0.6rem",
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            paddingTop: "0.8rem",
                            paddingBottom: "0.8rem",
                          }}
                        >
                          <div>
                            <p style={textStyle}>
                              Name :{" "}
                              <span style={normalTextStyle}>
                                {value[postionCount - 1]?.name}
                              </span>
                            </p>
                            <p className="font-link" style={textStyle}>
                              DOB :{" "}
                              <span style={normalTextStyle}>
                                {value[postionCount - 1]?.date_of_birth &&
                                  moment(
                                    value[postionCount - 1]?.date_of_birth
                                  ).format("DD/MM/YYYY")}
                              </span>
                            </p>
                            <p className="font-link" style={textStyle}>
                              Gender :{" "}
                              <span style={normalTextStyle}>
                                {value[postionCount - 1]?.sex === "M"
                                  ? "Male"
                                  : "Female"}
                              </span>
                            </p>

                            <p className="font-link" style={textStyle}>
                              Phone No.:{" "}
                              <span style={normalTextStyle}>
                                {value[postionCount - 1]?.mob_no}
                              </span>
                            </p>
                          </div>
                          <div
                            style={{
                              height: "4rem",
                              position: "absolute",
                              bottom: "2.1rem",
                              right: "0.6rem",
                            }}
                          >
                            <QRCode
                              style={{
                                height: "auto",
                                width: "100%",
                                maxHeight: "100%",
                              }}
                              value={`http://shc.singlepage.distronix.in?citizen=${
                                value[postionCount - 1]?.id
                              }`}
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Bottom Section */}
                      <div
                        style={{
                          backgroundColor: "#7fb1be",
                          padding: "1px",
                        }}
                      >
                        <p className="text-white text-2xl text-center font-semibold tracking-widest">
                          {value[postionCount - 1]?.card_no
                            ? value[postionCount - 1]?.card_no
                            : "0123456"}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="flex items-center justify-center m-10"
                    style={{
                      width: "321.26px",
                      height: "202px",
                    }}
                  />
                );
              }
            })
          : value.map((item: any) => (
              <div
                className="flex items-center justify-center "
                style={{
                  width: "321.26px",
                  height: "202px",
                  marginLeft: "2.44rem",
                  marginRight: "2.44rem",
                  marginTop: "2.3rem",
                  marginBottom: "2.8rem",
                  overflow: "hidden",
                }}
              >
                <div
                  className=" rounded-xl  border-[1px] shadow-lg overflow-hidden"
                  style={{
                    // top: 200,
                    // zIndex: 100,
                    // position: "absolute",
                    height: "321.26px",
                    width: "202px",
                    border: "solid",
                    borderWidth: 1,
                    borderColor: "#000",
                    backgroundColor: "#ffffff",
                    transform: "rotate(90deg)",
                  }}
                >
                  {/* Top Section */}
                  <div
                    className=" h-2/5 w-full shadow-2xl"
                    style={{
                      backgroundColor: "#7fb1be",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.6)",
                    }}
                  >
                    <div className=" w-full pt-2 flex -mb-6  items-center justify-center ">
                      <img
                        src={NKDALogo}
                        alt="NKDA logo"
                        style={{
                          height: "40px",
                          width: "50px",
                          objectFit: "fill",
                          backgroundColor: "#000",
                        }}
                      />
                    </div>
                    <h1
                      className="font-link"
                      style={{ fontSize: 15, textAlign: "center" }}
                    >
                      NKDA - Smart Health Card
                    </h1>
                  </div>
                  {/* Profile Image */}
                  <div className=" w-full  flex -mt-14  items-center justify-center bg-[#7fb1be]">
                    <img
                      src={
                        item?.profile_image
                          ? `https://api.cca.distronix.in:3443/${item?.profile_image}`
                          : User
                      }
                      alt="UserPhoto"
                      style={{
                        height: "90px",
                        width: "95px",
                        objectFit: "fill",
                        backgroundColor: "#000",
                      }}
                    />
                  </div>

                  {/* Middle Sction */}
                  <div>
                    <div
                      style={{
                        paddingRight: "0.6rem",
                        paddingLeft: "0.6rem",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        paddingTop: "0.8rem",
                        paddingBottom: "0.8rem",
                      }}
                    >
                      <div>
                        <p style={textStyle}>
                          Name :{" "}
                          <span style={normalTextStyle}>{item?.name}</span>
                        </p>
                        <p className="font-link" style={textStyle}>
                          DOB :{" "}
                          <span style={normalTextStyle}>
                            {item?.date_of_birth &&
                              moment(item?.date_of_birth).format("DD/MM/YYYY")}
                          </span>
                        </p>
                        <p className="font-link" style={textStyle}>
                          Gender :{" "}
                          <span style={normalTextStyle}>
                            {item?.sex === "M" ? "Male" : "Female"}
                          </span>
                        </p>

                        <p className="font-link" style={textStyle}>
                          Phone No.:{" "}
                          <span style={normalTextStyle}>{item?.mob_no}</span>
                        </p>
                      </div>
                      <div
                        style={{
                          height: "4rem",
                          position: "absolute",
                          bottom: "2.1rem",
                          right: "0.6rem",
                        }}
                      >
                        <QRCode
                          style={{
                            height: "auto",
                            width: "100%",
                            maxHeight: "100%",
                          }}
                          value={`http://shc.singlepage.distronix.in?citizen=${item?.id}`}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Bottom Section */}
                  <div
                    style={{
                      backgroundColor: "#7fb1be",
                      padding: "1px",
                    }}
                  >
                    <p className="text-white text-2xl text-center font-semibold tracking-widest">
                      {item?.card_no ? item?.card_no : "0123456"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
      </div>
    );
  }
);
