import { Formik, Form } from "formik";
import React, { useRef, useState } from "react";
import "./Modal.css";
import DropDown from "../../components/Dropdown";
import InputBox from "../../components/InputField/InputBox";
import { useAppSelector } from "../../app/hooks";
import * as Yup from "yup";
export default function Modal() {
  const [show, setShow] = useState(false);
  const drop = useRef(null);

  window.onclick = (e) => {
    if (e.target == drop.current) {
      let modal = document.getElementById("modal");
      modal?.classList.remove("launch-modal");
      modal?.classList.add("modal-close");
      setShow(false);
    }
  };
  const initialValues = {
    name: "",
    cont_no: "",
    addr: "",
    lat: "",
    long: "",
  };

  const { accessToken } = useAppSelector((s) => s.user);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <button
          className="border p-3 px-4 rounded cursor-pointer mb-3 bg-indigo-600 text-white"
          onClick={() => setShow(!show)}
        >
          Add Medical Center
        </button>
      </div>
      {show && (
        <div
          ref={drop}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            minHeight: "96.5vh",
            overflowY: "scroll",
            position: "absolute",
            top: "0%",
            backdropFilter: "blur(10px)",
            left: "0%",
            zIndex: 1030,
          }}
          id="modal"
          className="launch-modal"
        >
          <div
            style={{
              borderRadius: "10px",
              padding: "25px",
              position: "absolute",
              width: "50%",
              // height: '80%',
              borderWidth: "1px",
              borderStyle: "solid",
              gap: "5px",
              marginTop: "15px",
              // zIndex: 1035,
              // display: "grid",
            }}
            className="bg-white"
          >
            <span className="text-2xl font-semibold text-center">
              Add Medical Center
            </span>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Required"),
                cont_no: Yup.string()
                  .matches(/^[0-9]{10}$/, "Invalid Contact Number") // Assuming a 10-digit mobile number
                  .required("Required"),
                lat: Yup.number()
                  .max(10, "Not more then 10 digit")
                  .required("Required")
                  .typeError("Invalid Latitude"),
                long: Yup.number()
                  .required("Required")
                  .typeError("Invalid Longitude"),
                addr: Yup.string().required("Required"),
                // .matches(
                //   /^[a-zA-Z0-9@]+$/,
                //   "This field cannot contain white space and special character"
                // ),
              })}
              onSubmit={async (values: any) => {
                values.cont_no = `${values.cont_no}`;
                console.log(values);
                await fetch(
                  ` https://api.cca.distronix.in:3443/caller/medicalCenter/createOne`,
                  {
                    method: "POST",
                    body: JSON.stringify(values),
                    headers: {
                      Authorization: `${accessToken}`,
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then((response) => {
                    return response.json();
                  })
                  .then((data) => {
                    console.log(data);
                    setShow(!show);
                    if (data.status === "success") {
                      console.log(data);
                      window.location.reload();
                    } else {
                      console.log(data);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              {({
                //@ts-ignore
                errors,
                values,
                //@ts-ignore
                touched,
              }) => (
                <Form className="flex flex-col">
                  <div className="grid sm:grid-cols-3 gap-4 p-3 pb-6 gap-y-6">
                    <InputBox
                      name={"name"}
                      label={"Name"}
                      type={"text"}
                      error={touched.name && errors.name}
                      asterisk={true}
                    />
                    <InputBox
                      name={"addr"}
                      label={"Addess"}
                      type={"text"}
                      error={touched.addr && errors.addr}
                      asterisk={true}
                    />
                    <InputBox
                      name={"cont_no"}
                      label={"Phone No"}
                      type={"text"}
                      numLength={10}
                      error={touched.cont_no && errors.cont_no}
                      asterisk={true}
                    />
                    <InputBox
                      name={"lat"}
                      label={"Lattitude"}
                      numLength={10}
                      error={touched.lat && errors.lat}
                      asterisk={true}
                    />
                    <InputBox
                      name={"long"}
                      label={"Longitude"}
                      error={touched.long && errors.long}
                      asterisk={true}
                    />
                  </div>
                  <div>
                    <button
                      className="ml-3 border p-3 rounded-md w-28 text-center transition duration-500 cursor-pointer bg-indigo-600 text-white"
                      type="submit"
                    >
                      Add New
                    </button>
                    <button
                      className="ml-3 border p-3 rounded-md w-28 text-center transition duration-500 cursor-pointer bg-red-600 text-white"
                      onClick={() => setShow(!show)}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
}
