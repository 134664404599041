import React, { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";

type Props = {
  data: any;
};

const StatisticChart = ({ data }: Props) => {
  const totalCardGenerated = Object.values(data).map(
    (entry: any) => entry.new_card_count
  );

  const totalPatientVisited = Object.values(data).map(
    (entry: any) => entry.patient_visited_count
  );

  const totalCardLost = Object.values(data).map(
    (entry: any) => entry.lost_card_count
  );

  const xlavel = Object.keys(data);
  const [options, setOptions] = useState<any>({
    series: [
      {
        name: "Total Card Generated",
        data: totalCardGenerated,
      },
      {
        name: "Total Patient Visited",
        data: totalPatientVisited,
      },
      {
        name: "Total Card Lost",
        data: totalCardLost,
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      stacked: false,

      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        borderRadius: 10,
        borderRadiusApplication: "end",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (
        value: string,
        { seriesIndex, dataPointIndex, w }: any
      ) {
        return value;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    xaxis: {
      categories: xlavel,
      labels: {
        formatter: function (value: any) {
          // Use a custom formatter to break the label into two lines
          const words = value.toString().split(" ");
          return [
            words[0],
            words.slice(1, 3).join(" "),
            words.slice(3).join(" "),
          ];
        },
        // rotate: -45,
        offsetY: 4.5,
        style: {
          fontFamily: "Inter",
          cssClass: "apexcharts-label",
          fontWeight: 600,
        },
      },
      title: {
        text: "Total branches",
        offsetX: 0,
        offsetY: 4,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    yaxis: {
      title: {
        text: "Kpi data",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "darker",
        type: "vertical",
        shadeIntensity: 0.3,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 75, 100],
        colorStops: [],
      },
    },
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
    legend: {
      show: true,
    },
    grid: {
      strokeDashArray: [5, 5],
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }: any) {
        if (seriesIndex === 0) {
          return "#94efd4";
        }
        if (seriesIndex === 1) {
          return "#ff8181";
        }
        if (seriesIndex === 2) {
          return "#9cff85";
        }
      },
    ],
  });

  useEffect(() => {
    setOptions({
      series: [
        {
          name: "Total Card Generated",
          data: totalCardGenerated,
        },
        {
          name: "Total Patient Visited",
          data: totalPatientVisited,
        },
        {
          name: "Total Card Lost",
          data: totalCardLost,
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: false,

        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          borderRadius: 10,
          borderRadiusApplication: "end",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (
          value: string,
          { seriesIndex, dataPointIndex, w }: any
        ) {
          return value;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 4,
        colors: ["transparent"],
      },
      xaxis: {
        categories: xlavel,
        labels: {
          formatter: function (value: any) {
            // Use a custom formatter to break the label into two lines
            const words = value.toString().split(" ");
            return [
              words[0],
              words.slice(1, 3).join(" "),
              words.slice(3).join(" "),
            ];
          },
          // rotate: -45,
          offsetY: 4.5,
          style: {
            fontFamily: "Inter",
            cssClass: "apexcharts-label",
            fontWeight: 600,
          },
        },
        title: {
          text: "Total branches",
          offsetX: 0,
          offsetY: 4,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
      },
      yaxis: {
        title: {
          text: "Kpi data",
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "darker",
          type: "vertical",
          shadeIntensity: 0.3,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 75, 100],
          colorStops: [],
        },
      },
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: false,
        },
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
      legend: {
        show: true,
      },
      grid: {
        strokeDashArray: [5, 5],
      },
      colors: [
        function ({ value, seriesIndex, dataPointIndex, w }: any) {
          if (seriesIndex === 0) {
            return "#94efd4";
          }
          if (seriesIndex === 1) {
            return "#ff8181";
          }
          if (seriesIndex === 2) {
            return "#9cff85";
          }
        },
      ],
    });
  }, [data]);
  return (
    <div>
      {/* @ts-ignore */}
      <Chart
        options={options}
        series={options?.series}
        type="bar"
        height={
          window.screen.height > 864
            ? window.screen.height * 0.4
            : window.screen.height * 0.4
        }
      />
    </div>
  );
};

export default StatisticChart;
