import {
  configureStore,
  combineReducers,
} from '@reduxjs/toolkit';

import {
  persistReducer,
  persistStore,
} from 'redux-persist';
import userSlice from '../features/user/userSlice';
import storage from "redux-persist/lib/storage";
import toggleSlice from '../features/toggle/toggleSlice';
import transactionSlice from '../features/document/transactionSlice';

const rootReducer = combineReducers({
  user: userSlice,
  toggle:toggleSlice,
  transaction:transactionSlice
});
const persistConfig = {
  key: 'root',
  storage: storage,
  // blacklist: ['doc'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,

});
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
