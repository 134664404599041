import React, { Fragment, SyntheticEvent, useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
// import { CalendarSvg } from "../../assets/KpiSummary/index";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import moment from "moment";
import { DateRange } from "react-date-range";

const DateRangePicker = ({
  onDateRangeChnage,
}: {
  onDateRangeChnage: (arg: any) => void;
}) => {
  const [isEndDate, setIsEndDate] = useState<string>();
  const [isStartDate, setIsStartDate] = useState<string>();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = (url?: string) => {
    if (url) {
      // router.push(url)
    }
    setAnchorEl(null);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const onChangeHandler = (item: any) => {
    setState([
      //@ts-ignore
      item.selection,
    ]);
  };

  var endDate: string = moment(state[0].endDate).format("DD/MM/YYYY");
  var startDate: string = moment(state[0].startDate).format("DD/MM/YYYY");

  useEffect(() => {
    endDate = moment(state[0].endDate).format("DD/MM/YYYY");
    startDate = moment(state[0].startDate).format("DD/MM/YYYY");
    setIsEndDate(endDate);
    setIsStartDate(startDate);
    if (endDate !== startDate) {
      setAnchorEl(null);
    }
    onDateRangeChnage(state);
  }, [state]);

  return (
    <Fragment>
      <div
        onClick={handleDropdownOpen}
        className="flex w-full bg-white border cursor-pointer items-center py-[1px] rounded px-2"
      >
        {/* <img src={CalendarSvg} className="xl:h-3 xl:w-3 2xl:h-4 2xl:w-4" /> */}
        <i className="fa fa-solid fa-calendar"></i>&ensp;
        <div className="select-none lg:text-[9px] xl:text-[10px] 2xl:text-[10px] mx-1 leading-10 ">
          {isStartDate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          {isEndDate}
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiMenu-paper": {
            width: 330,
            borderRadius: 4,
            alignItems: "center",
            marginTop: "20px",
          },
        }}
      >
        <DateRange
          editableDateInputs={true}
          onChange={(item) => onChangeHandler(item)}
          moveRangeOnFirstSelection={false}
          //@ts-ignore
          ranges={state}
        />
      </Menu>
    </Fragment>
  );
};

export default DateRangePicker;
