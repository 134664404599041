import { Popover } from "@mui/material";
import React from "react";
import markerIcon from "../../assets/images/location.png";
import "./style.css";
import { Datum } from "../../pages/Map/Map";
import TableTailwind from "../TableTailwind/TableTailwind";
const Marker: React.FC<{
  id: number;
  lat: number | undefined;
  lng: number | undefined;
  data: Datum[];
  clicked: number | null;
  centerName: string;
  setClicked: React.Dispatch<React.SetStateAction<number | null>>;
}> = ({ id, lat, lng, data, clicked, setClicked,centerName }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setClicked(id);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popid = open ? "simple-popover" : undefined;

  return (
    <>
      <div
        className={clicked === id && !!open ? "marker" : ""}
        onClick={(e) => handleClick(e)}
      >
        <img
          src={markerIcon}
          style={{
            width: 55,
            height: 55,
            objectFit: "contain",
            // marginBottom: -1,
            cursor: "pointer",
            // border:'1px solid black'
          }}
        />
      </div>
      {open && (
        <Popover
          id={String(popid)}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <TableTailwind data={data} lat={lat} lng={lng} centerName={centerName} />
        </Popover>
      )}
    </>
  );
};

export default Marker;
