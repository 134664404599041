import axios, { AxiosError, AxiosRequestConfig } from "axios";

export const EditShcUser = async (
  key: string,
  data: any,
  id: number | undefined
) => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: `https://api.cca.distronix.in:3443/caller/shcUser/updateOne/${id}`,
    headers: {
      authorization: key,
    },
    data: data,
  };
  const result = axios(config)
    .then((e: any) => e)
    .catch((e: any) => {
      e as AxiosError;
      return e;
    });
  return result;
};
