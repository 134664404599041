import { Field, FormikErrors } from "formik";
import React, { useEffect, useRef, useState } from "react";

type BoxData = {
  name: string;
  label: string;
  type: string;
  apiValue?: string[];
  value?: string;
  asterisk?: boolean;
  setVal?: (
    field: string,
    value: string | number,
    shouldValidate?: boolean
  ) => void;
  error?: FormikErrors<{}> | undefined;
};

export default function InputSearch(props: BoxData) {
  const [show, setShow] = useState(false);
  const drop = useRef(null);

  // BackDrop Implementation

  useEffect(() => {
    const backDrop = (e: any) => {
      var path = e.path || (e.composedPath && e.composedPath());
      if (path[2] !== drop.current && path[1] !== drop.current) {
        setShow(false);
      }
    };
    document.body.addEventListener("click", backDrop);
    return () => document.body.removeEventListener("click", backDrop);
  }, []);

  useEffect(() => {
    document.activeElement?.tagName === "INPUT" && setShow(true);
  }, [props.value]);

  const valueHandler = (e: string | number) => {
    props.setVal?.(props.name, e);
    setShow(!show);
  };

  const searchResult: string[] | undefined = React.useMemo(() => {
    return props.apiValue!.filter((s: string) =>
      s.toLowerCase().includes(props.value!.toLowerCase())
    );
  }, [props.value]);

  return (
    <div className="z-10">
      <div className="relative">
        <label
          className={`font-normal text-sm tracking-wide w-full ${
            props.error && "text-[#FF0000]"
          }`}
        >
          {props.label}
          {props.asterisk && <span className=" text-red-500 -mt-2">*</span>}
        </label>
      </div>
      <Field
        type={props.type}
        name={props.name}
        autocomplete="off"
        className={`w-full bg-white border-gray-300 focus:border-indigo-800  ${
          props.error && "border-[#FF0000]"
        } rounded border text-sm outline-none text-gray-700 px-2 leading-10 `}
      />
      {props.error && (
        <div className="text-[#FF0000] text-xs">
          {/* @ts-ignore */}
          {props.error}
        </div>
      )}
      {show && (
        <ol
          className={`text-[0.8rem] bg-gray-50 rounded-b-[0.35rem] p-2 max-h-64 overflow-y-scroll  border shadow-md flex-col scroller`}
          ref={drop}
        >
          {searchResult.map((element: any) => {
            return (
              <option
                className="hover:bg-white cursor-pointer pl-3 rounded p-1"
                onClick={() => valueHandler(element)}
              >
                {element}
              </option>
            );
          })}
        </ol>
      )}
    </div>
  );
}
