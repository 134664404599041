import { Field, FormikErrors } from "formik";
import "./Style.css";
type InputBoxData = {
  name: string;
  label?: string;
  type?: string;
  numLength?: number;
  id?: string;
  placeholder?: string;
  message?: string;
  value?: string;
  asterisk?: boolean;
  max?: string;
  error?: FormikErrors<{}> | undefined;
  className?: string;
};

export default function InputBox(props: InputBoxData) {
  return (
    <div className="relative">
      <label
        className={`font-normal text-sm tracking-wide w-full ${
          props.error && "text-[#FF0000]"
        }`}
      >
        {props?.label}
        {props.asterisk && <span className=" text-red-500 -mt-2">*</span>}
      </label>

      {props?.value ? (
        <Field
          type={props?.type}
          id={props?.id}
          name={props?.name}
          placeholder={props?.placeholder}
          maxLength={props?.max}
          value={props?.value}
          disabled={props?.value ? true : false}
          onInput={(e: any) => {
            return props.type === "number"
              ? (e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, props?.numLength ? props?.numLength : 10))
              : e.target.value;
          }}
          className={`w-full ${
            props?.value ? "bg-[#f1efef] cursor-not-allowed" : "bg-white"
          }  border-gray-300 rounded border focus:border-indigo-800 text-base outline-none text-gray-700 px-2 py-1 hideArrows  ${
            props.error && "border-[#FF0000]"
          } ${props?.className}`}
        />
      ) : (
        <Field
          type={props?.type}
          id={props?.id}
          name={props?.name}
          placeholder={props?.placeholder}
          maxLength={props?.max}
          disabled={props?.value ? true : false}
          onInput={(e: any) => {
            return props.type === "number"
              ? (e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, props?.numLength ? props?.numLength : 10))
              : e.target.value;
          }}
          className={`w-full ${
            props?.value ? "bg-[#f1efef] cursor-not-allowed" : "bg-white"
          }  border-gray-300 rounded border focus:border-indigo-800 text-base outline-none text-gray-700 px-2 py-2 hideArrows ${
            props.error && "border-[#FF0000]"
          } ${props?.className}`}
        />
      )}
      <div className="text-[#FF0000] text-xs text-red-500">
        {/* @ts-ignore */}
        {props.error}
      </div>
    </div>
  );
}
