import moment from "moment";
import React from "react";
import { Datum } from "../../pages/Map/Map";
import styles from "./styles.module.css";
import hospitalIcon from "../../assets/images/hospital.png";
import { GrMapLocation, GrTransaction } from "react-icons/gr";

const TableTailwind: React.FC<{
  data: Datum[];
  lat: number | undefined;
  lng: number | undefined;
  centerName: string;
}> = ({ data, lat, lng, centerName }) => {
  return (
    <div className="flex items-center justify-center h-1/6 bg-gray-900 overflow-scroll z-50 pl-2.5 rounded">
      <div className="col-span-12">
        <div className="overflow-auto lg:overflow-visible ">
          {/* <GrMapLocation size={100} color='#404ccf'/> */}
          <div className="flex justify-center items-center mb-0 pt-4">
            <img src={hospitalIcon} className="h-7 w-7 resize" />
            <p className="text-center text-gray-400 font-bold ">{centerName}</p>
          </div>
          <table
            className={styles.table + " text-gray-400 border-separate text-sm"}
          >
            <thead className="bg-gray-800 text-gray-500">
              <tr>
                <th className="p-3 text-left font-bold">Citizen Id</th>
                <th className="p-3 text-left font-bold">Profile Image</th>
                <th className="p-3 text-left font-bold">Name</th>
                <th className="p-3 text-left font-bold">Number</th>
                <th className="p-3 text-left font-bold flex-wrap w-5">
                  No. of Transaction
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr className="bg-gray-800">
                  <td className="p-3 ">{item.citizen_id}</td>
                  <td className="p-3 ">
                    <img
                      className="rounded-full h-12 w-12  object-cover"
                      src={`https://api.cca.distronix.in:3443/${item.profile_image.slice(
                        10
                      )}`}
                      alt="image"
                    />
                  </td>
                  <td className="p-3">{item.name}</td>
                  <td className="p-3">{item.mob_no}</td>
                  <td className="p-3 text-center">
                    <span className="bg-red-400 text-gray-50 rounded-md px-2">
                      {item.count}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableTailwind;
