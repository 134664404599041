import axios, {AxiosError, AxiosRequestConfig} from 'axios';

export interface data {
  username: string, password: string
}
export const LoginWithJWTApi = async (data:data) => {

    var user = JSON.stringify(data);

        const config: AxiosRequestConfig = {
            method: 'post',
            url: 'https://api.cca.distronix.in:3443/caller/login',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : user
        }
     const result = axios(config).then((e:any)=> e).catch((e:any)=>{
       e as AxiosError
       return e
     }) 
     return result  
    }
  
