import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  RiDashboardFill,
  RiDashboard2Fill,
  RiMapFill,
  BsArrowBarRight,
  BsArrowBarLeft,
  RiDatabase2Fill,
} from "react-icons/all";
import "./style.scss";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { FaRegistered } from "react-icons/fa";
import { AiFillIdcard } from "react-icons/ai";
import { BiDuplicate } from "react-icons/bi";
import FormModal from "../Modal/FormModal";
import RegisterHSC from "../Modal/RegisterHSC";
import AllocateHSC from "../Modal/AllocateHSC";
import DuplicateHSC from "../Modal/DuplicateHSC";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setToggle } from "../../features/toggle/toggleSlice";
import { GetAllRegistered } from "../../api/CardDataApi/getAllRegistered";
const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const { accessToken } = useAppSelector((state) => state.user);
  const { toggle } = useAppSelector((state) => state.toggle);
  const dispatch = useAppDispatch();

  return (
    <>
      <FormModal open={open} setOpen={setOpen} />
      <RegisterHSC open={open2} setOpen={setOpen2} />
      <AllocateHSC open={open3} setOpen={setOpen3} />
      <DuplicateHSC open={open4} setOpen={setOpen4} />
      <ProSidebar style={{ height: "100%" }} collapsed={toggle}>
        <SidebarHeader>
          {/**
           *  You can add a header for the sidebar ex: logo
           */}

          <Menu iconShape="square">
            {toggle ? (
              <MenuItem
                title="Expand Menu"
                icon={
                  <BsArrowBarRight
                    size="25px"
                    onClick={() => dispatch(setToggle())}
                  />
                }
              ></MenuItem>
            ) : (
              <MenuItem
                title="Collapse Menu"
                icon={<BsArrowBarLeft size="25px" />}
                onClick={() => dispatch(setToggle())}
              >
                Collapse Sidebar
              </MenuItem>
            )}
          </Menu>
        </SidebarHeader>
        <SidebarContent>
          {/**
           *  You can add the content of the sidebar ex: menu, profile details, ...
           */}

          <Menu iconShape="round" title="SHC MODULE">
            <h3 style={{ padding: "0 10px" }}>SHC Module</h3>

            {/* <MenuItem
              title="Add New Patient"
              icon={toggle && <FaRegistered size={18} />}
            onClick={() => setOpen1(true)}
            >
              <button className="buttonClass">
                <Modal show={open1} /> Add New Patient
              </button>
            </MenuItem> */}
            {/* <MenuItem
              title="Register New SHC"
              icon={toggle && <FaRegistered size={18} />}
              onClick={() => setOpen2(true)}
            >
              <button className="buttonClass">
                {" "}
                <FaRegistered size={16} />&nbsp; Register New SHC
              </button>
            </MenuItem> */}
            <MenuItem
              title="Allocate SHC"
              icon={toggle && <AiFillIdcard size={18} />}
              onClick={() => setOpen3(true)}
            >
              <button className="buttonClass">
                {" "}
                <AiFillIdcard size={18} />
                &nbsp; Allocate SHC
              </button>
            </MenuItem>
            {/* <MenuItem
              title="Generate Duplicate SHC"
              icon={toggle && <BiDuplicate size={18} />}
              onClick={() => setOpen4(true)}
            >
              <button  className="buttonClass">
                {" "}
                <BiDuplicate size={18} />{" "}
                <p>Generate Duplicate SHC</p>
              </button>
            </MenuItem> */}
            {/* <MenuItem
              title="Add Health Center"
              icon={toggle && <MdOutlineAddLocationAlt size={18} />}
              onClick={() => setOpen(true)}
            >
              <button className="buttonClass">
                {" "}
                <MdOutlineAddLocationAlt size={18} /> <p>Add Health Center</p>
              </button>
            </MenuItem> */}
            {/* <MenuItem icon={<RiDashboardFill />}>
              Dashboard
              <Link to="/" />
            </MenuItem> */}
            <MenuItem title="Dashboard" icon={<RiMapFill />}>
              Dashboard
              <Link to="/" />
            </MenuItem>
            <MenuItem title="Map View" icon={<RiMapFill />}>
              Map View
              <Link to="/map" />
            </MenuItem>

            <SubMenu title="Master Data" icon={<RiDatabase2Fill />}>
              <MenuItem>
                Authorised Center
                <Link to="/masterdata/medicalcenter" />
              </MenuItem>
              {/* <MenuItem>Medical Center
                <Link to="/masterdata/patienttransaction" />
              </MenuItem> */}
              <MenuItem>
                Citizen Transcation
                <Link to="/masterdata/patienttransaction" />
              </MenuItem>
              <MenuItem>
                Patient Data
                <Link to="/masterdata/patient" />
              </MenuItem>
            </SubMenu>
            <MenuItem title="Map View" icon={<RiMapFill />}>
              Upload Document
              <Link to="/uploaddocument" />
            </MenuItem>
            {/* <SubMenu title="Upload Data" icon={<RiDatabase2Fill />}>
              <MenuItem title="Map View" icon={<RiMapFill />}>
                Upload Document
                <Link to="/map" />
              </MenuItem>
              <MenuItem title="Map View" icon={<RiMapFill />}>
                Diagonistics
                <Link to="/map" />
              </MenuItem>
            </SubMenu> */}
            {/* <SubMenu title="Reports" icon={<RiDashboard2Fill />}>
              <MenuItem>Report 1</MenuItem>
              <MenuItem>Report 2</MenuItem>
            </SubMenu> */}
          </Menu>
          <Menu
            iconShape="round"
            title="COVID MODULE"
            style={{ borderTop: "0.5px solid #3F4551" }}
          >
            <h3 style={{ padding: "0 10px" }}>Disease Module</h3>

            <MenuItem icon={<RiDashboardFill />}>
              <a
                target={"_blank"}
                href={`http://nkda.cca.distronix.in/user/callcenter?${accessToken}`}
                rel="noreferrer"
              >
                Patient Data
              </a>
            </MenuItem>
            {/* <MenuItem icon={<RiMapFill />}>
              <a
                target={"_blank"}
                href={`http://nkda.cca.distronix.in/user/patients?${accessToken}`}
              >
                Patient Master
              </a>
            </MenuItem>
            <MenuItem title="Sanitization" icon={<RiDatabase2Fill />}>
              <a
                target={"_blank"}
                href={`http://nkda.cca.distronix.in/user/sanitization?${accessToken}`}
              >
                Sanitization
              </a>
            </MenuItem>
            <MenuItem title="Reports" icon={<RiDashboard2Fill />}>
              <a
                target={"_blank"}
                href={`http://nkda.cca.distronix.in/user/reports?${accessToken}`}
              >
                Reports
              </a>
            </MenuItem>
            <MenuItem title="Bulletin" icon={<RiDashboard2Fill />}>
              <a
                target={"_blank"}
                href={`http://nkda.cca.distronix.in/user/bullatin?${accessToken}`}
              >
                Bulletin
              </a>
            </MenuItem> */}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          {/**
           *  You can add a footer for the sidebar ex: copyright
           */}
        </SidebarFooter>
      </ProSidebar>
    </>
  );
};

export default Sidebar;
