export const BloodGroupGenerator = (bg: string) => {
  if (bg === "A Positive") {
    return "A+";
  }
  if (bg === "B Positive") {
    return "B+";
  }
  if (bg === "A Negative") {
    return "A-";
  }
  if (bg === "B Negative") {
    return "B-";
  }
  if (bg === "O Positive") {
    return "O+";
  }
  if (bg === "O Negative") {
    return "O-";
  }
  if (bg === "AB Negative") {
    return "AB-";
  }
  if (bg === "AB Positive") {
    return "AB+";
  }
  return "Select";
};
export const BloodGroupShowFormat = (bg: string) => {
  const bgWithTrim = bg?.trim();
  if (bgWithTrim === "A+") {
    return "A Positive";
  }
  if (bgWithTrim === "B+") {
    return "B Positive";
  }
  if (bgWithTrim === "A-") {
    return "A Negative";
  }
  if (bgWithTrim === "B-") {
    return "B Negative";
  }
  if (bgWithTrim === "O+") {
    return "O Positive";
  }
  if (bgWithTrim === "O-") {
    return "O Negative";
  }
  if (bgWithTrim === "AB-") {
    return "AB Negative";
  }
  if (bgWithTrim === "AB+") {
    return "AB Positive";
  }
  return "Select";
};

export const FindYesNo = (values: any) => {
  if (values === null) {
    return "Select";
  }
  if (values === false) {
    return "No";
  }
  if (values === true) {
    return "Yes";
  }
  return "Select";
};
