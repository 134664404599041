import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import InputField from "../InputField/InputField";
import OtpInput from "react-otp-input";
import OtpModal from "./OtpModal";
import { AiOutlineUserAdd } from "react-icons/ai";

const RegisterUser: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {

  const [secondOpen, setSecondOpen] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          number: null,
          username: "",
          password: "",
    
        }}
        onSubmit={(values, actions) => {
          console.log(values);
        }}
        validationSchema={Yup.object().shape({
         
          email: Yup.string().email().required("Required"),
          number: Yup.number()
            .typeError("Invalid Contact Number")
            .required("Required"),
          username: Yup.string().required("Required"),
          password:Yup.string()
            .required('No password provided.') 
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')

        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            initialValues,
          } = props;
          console.log(errors);
          return (
            <Modal
              size="tiny"
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              dimmer={"blurring"}
            >
              <Modal.Header>
                {" "}
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <AiOutlineUserAdd color="#89dee2" size='25' />
                    Add New Admin User
                </p>
              </Modal.Header>

              <Modal.Content scrolling>
                <Modal.Description>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: 15,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 15,
                        width: "100%",
                      }}
                    >
                      <InputField
                        label="Email"
                        props={props}
                        name={Object.keys(initialValues)[0]}
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                      />
                      </div>
                      <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 15,
                        width: "100%",
                      }}
                    >
               
                      <InputField
                        label="Mobile Number"
                        props={props}
                        name={Object.keys(initialValues)[1]}
                        value={values.number}
                        error={errors.number}
                        touched={touched.number}
                      />
           
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 15,
                        width: "100%",
                      }}
                    >
                      <InputField
                        label="User ID"
                        props={props}
                        name={Object.keys(initialValues)[2]}
                        value={values.username}
                        error={errors.username}
                        touched={touched.username}
                      />
                      <InputField
                        label="password"
                        props={props}
                        name={Object.keys(initialValues)[3]}
                        value={values.password}
                        error={errors.password}
                        touched={touched.password}
                      />
                    </div>
                  </div>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                  Close <Icon name="close" />
                </Button>
                <form onSubmit={handleSubmit} style={{ all: "initial" }}>
                  <Button
                    onClick={() => setSecondOpen(true)}
                    primary
                    type="submit"
                    // disabled={isSubmitting}
                  >
                    Add <Icon name="add" />
                  </Button>
                </form>
              </Modal.Actions>
               <OtpModal
                 open={secondOpen}
                 setOpen={setSecondOpen}
               />
            
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default RegisterUser;
