import React from 'react'

type Card = {
    text: string;
    color: string;
    number: string;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export default function Card(props: Card) {
  return (
    <div className='w-full h-40 rounded-lg flex flex-col justify-end' style={{backgroundColor: `${props.color}`}}>
        <div className='p-3 text-4xl font-semibold pl-4' style={{color: '#464E5F'}}>{props?.number}</div>
        <div className='p-3 text-2xl font-semibold pl-4' style={{color: '#464E5F'}}>{props?.text}</div>
    </div>
  )
}
