import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface TransactionHistory {
  id: number;
  citizen_id: number;
  lat: number;
  long: number;
  createdat: Date;
  count: number;
}

export interface TransactionHistoryState {
  id: number;
  name: string;
  mob_no: string;
  profile_image: string;
  TransactionHistory: TransactionHistory[];
}

interface TransactionState{
  transaction: TransactionHistoryState[];
}

const initialState:TransactionState = {
     transaction:[]
}

const transactionSlice = createSlice({
  name: 'TransactionHistory',
  initialState,
  reducers: {
    addTransaction: (state, action: PayloadAction<TransactionHistoryState[]>) => {
         state.transaction = action.payload
    },

  },
});

export const { addTransaction } = transactionSlice.actions;

export default transactionSlice.reducer
