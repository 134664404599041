import React from "react";
import { Modal } from "semantic-ui-react";

type Props = {
  onCloseHandler: (data: any) => void;
  selectedRowsValue: any[];
};

const SelectPostionForMultiplePrints = (props: Props) => {
  const [isActive, setIsActive] = React.useState<number>(1);
  const [selectedPositions, setSlectedPositions] = React.useState<any[]>([]);

  const onChoosePosition = (args: { t: number; l: number }) => {
    // if this value already exisiting
    const isExist = selectedPositions.findIndex(
      (item) => item.t === args.t && item.l === args.l
    );

    if (isExist !== -1) {
      setSlectedPositions([
        ...selectedPositions.filter(
          (item) => !(item.t === args.t && item.l === args.l)
        ),
      ]);
    } else {
      setSlectedPositions((prev) => [...prev, args]);
    }
  };
  React.useEffect(() => {
    if (props?.selectedRowsValue.length === selectedPositions.length) {
      props?.onCloseHandler(selectedPositions);
    }
  }, [selectedPositions]);
  return (
    <Modal
      size="tiny"
      open={true}
      onClose={() => props?.onCloseHandler(null)}
      onOpen={() => true}
      dimmer={"blurring"}
    >
      <h1 className="text-center pt-2 -pb-2">Please select the position </h1>
      <div
        className="bg-white grid grid-cols-2 p-4 gap-4"
        style={{ height: "80vh" }}
      >
        <div
          onClick={() => {
            onChoosePosition({ t: 0, l: 0 });
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            selectedPositions.findIndex(
              (item) => item.t === 0 && item.l === 0
            ) !== -1 && "bg-blue-500"
          }`}
        >
          1
        </div>
        <div
          onClick={() => {
            onChoosePosition({ t: 0, l: 1 });
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            selectedPositions.findIndex(
              (item) => item.t === 0 && item.l === 1
            ) !== -1 && "bg-blue-500"
          }`}
        >
          2
        </div>
        <div
          onClick={() => {
            onChoosePosition({ t: 1, l: 0 });
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            selectedPositions.findIndex(
              (item) => item.t === 1 && item.l === 0
            ) !== -1 && "bg-blue-500"
          }`}
        >
          3
        </div>
        <div
          onClick={() => {
            onChoosePosition({ t: 1, l: 1 });
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            selectedPositions.findIndex(
              (item) => item.t === 1 && item.l === 1
            ) !== -1 && "bg-blue-500"
          }`}
        >
          4
        </div>
        <div
          onClick={() => {
            onChoosePosition({ t: 2, l: 0 });
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            selectedPositions.findIndex(
              (item) => item.t === 2 && item.l === 0
            ) !== -1 && "bg-blue-500"
          }`}
        >
          5
        </div>
        <div
          onClick={() => {
            onChoosePosition({ t: 2, l: 1 });
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            selectedPositions.findIndex(
              (item) => item.t === 2 && item.l === 1
            ) !== -1 && "bg-blue-500"
          }`}
        >
          6
        </div>
        <div
          onClick={() => {
            onChoosePosition({ t: 3, l: 0 });
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            selectedPositions.findIndex(
              (item) => item.t === 3 && item.l === 0
            ) !== -1 && "bg-blue-500"
          }`}
        >
          7
        </div>
        <div
          onClick={() => {
            onChoosePosition({ t: 3, l: 1 });
          }}
          className={`border-2  flex justify-center items-center border-black rounded-lg cursor-pointer  hover:bg-blue-200 ${
            selectedPositions.findIndex(
              (item) => item.t === 3 && item.l === 1
            ) !== -1 && "bg-blue-500"
          }`}
        >
          8
        </div>
      </div>
    </Modal>
  );
};

export default SelectPostionForMultiplePrints;
