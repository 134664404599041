/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import { useAppSelector } from "../../app/hooks";
import InputField from "../../components/InputField/InputField";
import InputBox from "../../components/InputField/InputBox";
import DropDown from "../../components/Dropdown";
import { CitizenTransactionData } from "../../api/CitizenData/citizenTranscation";
import InputSearch from "../../components/InputSearch";
import { Link } from "react-router-dom";
import PositionedSnackbar from "../../components/Snackbar";
import { useAlert } from "react-alert";

const UploadDocument = () => {
  const toastMsg = useAlert();
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const [preview, setPreview] = useState<File[]>([]);
  const [centerList, setCenterList] = useState([]);
  const [reload, setReload] = useState(-1);
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [sucMsg, setSucMsg] = useState("");
  const [isError, setIsError] = useState<any>(null);

  const handleImageChange = (e: any) => {
    setSelectedFile((existing) => existing.concat(Array.from(e.target.files)));
    setReload(Math.random());
  };
  const deleteHandler = (id: any) => {
    selectedFile.splice(id, 1);
    setReload(Math.random());
    console.log(reload);
    setSelectedFile(selectedFile);
  };

  const getCenterList = async () => {
    await fetch(
      `https://api.cca.distronix.in:3443/caller/medicalCenter/getAll`,
      {
        method: "get",
        headers: {
          Authorization: `${accessToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result: any) => {
        if (result?.data) {
          const listofCenter = result?.data.map(
            (item: any) => `${item.name} (${item.id})`
          );
          setCenterList(listofCenter);
        }
      });
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview([]);
      return;
    }
    setPreview(selectedFile);
  }, [reload, selectedFile]);

  const { accessToken } = useAppSelector((s) => s.user);
  const [citizen, setCitizen] = useState<any>([]);
  const loadTransactionData = async () => {
    const data = await CitizenTransactionData(accessToken);
    // console.log(data?.data?.data)
    if (data?.status === 200) {
      // console.log(data?.data?.data)
      const array: any = [];
      // @ts-ignore
      data?.data?.data.forEach((element) => {
        array.push(`${element.name} (${element.id}) (${element.mob_no})`);
      });
      setCitizen(array);
    }
  };

  useEffect(() => {
    loadTransactionData();
    getCenterList();
  }, []);

  // console.log(citizen)

  const initialValues = {
    title: "",
    citizen: "",
    doctype: "",
    nkda_report: "",
    doc_name: "",
    medicalCenter: "",
    nextVisitingDate: "",
  };
  const handleClose = () => {
    setOpen(!open);
  };
  const [down, setDown] = useState("");

  // const Download = async () => {
  //   await fetch(`https://api.cca.distronix.in:3443/${down}`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `${accessToken}`,
  //     },
  //   })
  //     .then((res) => res.blob())
  //     .then((res) => {
  //       if (res.type !== "application/json") {
  //         setOpen(!open);
  //         setSucMsg("Data Found!");
  //         setErrMsg("");
  //         const fr = new FileReader();
  //         fr.readAsText(res);
  //         fr.onloadend = () => {
  //           // @ts-ignore
  //           if (!fr.result.includes("failed")) {
  //             const aElement = document.createElement("a");
  //             aElement.setAttribute("download", "Uploaded document");
  //             const href = URL.createObjectURL(res);
  //             aElement.href = href;
  //             aElement.setAttribute("target", "_blank");
  //             aElement.click();
  //             URL.revokeObjectURL(href);
  //           }
  //         };
  //       } else {
  //         setOpen(!open);
  //         setSucMsg("");
  //         setErrMsg("No Data Found!");
  //       }
  //       window.location.reload();
  //     })
  //     .catch((err) => console.error(err));
  // };

  const submitHandler = async (values: any, { resetForm }: any) => {
    var formdata = new FormData();
    formdata.append(
      "medical_center_id",
      values?.medicalCenter.split("(")[1].split(")")[0]
    );
    formdata.append("doc_type", values?.doctype);
    formdata.append("next_visiting_date", values?.nextVisitingDate);
    formdata.append("citizen_id", values?.citizen.split("(")[1].split(")")[0]);

    selectedFile.forEach((file) => {
      // @ts-ignore
      formdata.append("upload", file);
    });
    try {
      // @ts-ignore
      await fetch(`https://api.cca.distronix.in:3443/caller/uploadReports`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: `${accessToken}`,
        },
      })
        .then((result) => result.json())
        .then((data) => {
          toastMsg.success(data?.message);
        });
    } catch (error) {
      toastMsg.error("Something went wrong!");
    }
  };
  return (
    <div>
      <PositionedSnackbar
        open={open}
        message_err={errMsg}
        message_suc={sucMsg}
        handleClose={handleClose}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          // await onSubmit(values);
        }}
      >
        {({
          //@ts-ignore
          errors,
          values,
          //@ts-ignore
          touched,
          setFieldValue,
        }) => (
          // <Form >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              padding: "15px",
              margin: "15px",
              gap: 15,
            }}
            className="border rounded-xl"
          >
            <div className="text-xl font-semibold">Upload Document</div>
            <div className="border p-5 rounded-xl gap-5 grid grid-cols-5">
              {/* <InputBox name={"title"} label="Title" type="text" /> */}
              <DropDown
                props={{
                  options: centerList,
                  name: "medicalCenter",
                  label: "Select Center",
                  asterisk: true,
                }}
              />
              <DropDown
                props={{
                  options: ["Prescription", "Diagnostic", "Immunization"],
                  name: "doctype",
                  label: "Document Type",
                  asterisk: true,
                }}
              />
              <InputBox
                name={"nextVisitingDate"}
                label="Next Visiting Date"
                type="date"
                asterisk={true}
              />
              {/* <DropDown props={{
                options: ["Yes", "No"],
                name: "nkda_report",
                label: "NKDA Report",
                asterisk: true,
              }} /> */}
              {/* {values.nkda_report === "No" && (
                <InputBox name={"doc_name"} label="Doctor Name" type="text" />
              )} */}
              <div className="h-14 z-10 sm:col-span-2">
                <InputSearch
                  name={"citizen"}
                  label={"SHC ID / Patient Name / Mobile No"}
                  type={"text"}
                  setVal={setFieldValue}
                  value={values.citizen}
                  apiValue={citizen}
                  asterisk={true}
                />
              </div>
              <div className="sm:col-span-2">
                <label>
                  Upload Documents{" "}
                  <span className=" text-red-500 -mt-2">*</span>
                </label>
                <div
                  style={{
                    height: "200px",
                    width: " 400px",
                  }}
                  className="border rounded-xl p-3 cursor-pointer"
                >
                  {preview.length > 0 ? (
                    <>
                      <div className="grid grid-cols-3 gap-2 h-full overflow-y-scroll">
                        {/* @ts-ignore */}
                        {preview.map((data, key) => {
                          return (
                            <div className="relative">
                              <img
                                src={URL.createObjectURL(data)}
                                className="w-40 h-48 p-1 rounded-xl"
                                alt=""
                                id={`${key}`}
                              />
                              <div
                                className="absolute right-2 top-2 border rounded-full bg-white bg-opacity-25 w-6 h-6 cursor-pointer"
                                onClick={() => deleteHandler(key)}
                              >
                                &#10060;
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={
                        //@ts-ignore
                        () => inputRef.current.click()
                      }
                    >
                      Please select an Image for Preview
                      <br />
                      <span>&#10133;</span>
                    </div>
                  )}
                </div>
                <input
                  id="file"
                  type="file"
                  style={{ display: "none" }}
                  ref={inputRef}
                  multiple
                  onChange={handleImageChange}
                />
              </div>
            </div>
            <div className="flex gap-5 items-center">
              <button
                type="submit"
                className="w-28 bg-indigo-600 p-3 text-white rounded-md hover:bg-indigo-500"
                onClick={(resetForm) => {
                  if (
                    selectedFile.length > 0 &&
                    values.citizen &&
                    values.nextVisitingDate &&
                    values?.doctype &&
                    values?.medicalCenter
                  ) {
                    submitHandler(values, { resetForm });
                  } else {
                    toastMsg.error("Please fill all mandatory fields.");
                  }
                }}
              >
                Submit
              </button>
              {/* {down && (
                <div>
                  <a
                    id="download"
                    onClick={(e) => Download()}
                    className="p-3 bg-blue-400 w-32 text-white hover:text-white rounded cursor-pointer"
                  >
                    Download
                  </a>
                </div>
              )} */}
            </div>
          </div>
          // </Form>
        )}
      </Formik>
    </div>
  );
};
export default UploadDocument;
