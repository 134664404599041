// import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { login, logout } from "../../features/user/userSlice";
import MedicalBg from "../../assets/images/medical-background.jpg";
import medical from "../../assets/images/medical-app.png";
import "./Login.css";
import Header from "../Header/Header";
import { Formik } from "formik";
import * as Yup from "yup";
import InputField from "../InputField/InputField";
import { data, LoginWithJWTApi } from "../../api/login/loginWithJWTApi";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const [errorDiv, setErrorDiv] = useState(false);

  const handleLogin = async (data: data) => {
    const result = await LoginWithJWTApi(data);

    if (!!result.data.name) {
      setErrorDiv(false);
      await dispatch(
        login({ name: result.data.name, accessToken: result.data.accessToken })
      );
    } else {
      setErrorDiv(true);
      await dispatch(logout());
    }
  };

  return (
    <div className="loginScreen" style={{ background: `url(${MedicalBg})` }}>
      <Header />
      <div className="loginScreen__background">
        <div className="loginScreen__gradient" />
      </div>
      <div className="loginScreen__body">
        <>
          <div className="signupScreen">
            <Formik
              initialValues={{ username: "", password: "" }}
              onSubmit={(values) => {
                handleLogin(values);
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().required("Required"),
                password: Yup.string()
                  .required("No password provided.")
                  .min(8, "Password is too short - should be 8 chars minimum.")
                  .matches(
                    /[a-zA-Z]/,
                    "Password can only contain Latin letters."
                  ),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  initialValues,
                } = props;
                console.log(errors);
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <img src={medical} className="Login__logo" />
                    {errorDiv && (
                      <div className="error_div">
                        Username or Password Incorrect
                      </div>
                    )}
                    <InputField
                      label="User Name"
                      props={props}
                      name={Object.keys(initialValues)[0]}
                      value={values.username}
                      error={errors.username}
                      touched={touched.username}
                    />
                    <InputField
                      label="Password"
                      props={props}
                      name={Object.keys(initialValues)[1]}
                      value={values.password}
                      error={errors.password}
                      touched={touched.password}
                    />
                    <button type="submit">Log In</button>
                  </form>
                );
              }}
            </Formik>
          </div>
        </>
      </div>
    </div>
  );
};

export default Login;
