import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import InputField from "../InputField/InputField";
import { useAppSelector } from "../../app/hooks";

const EditClinicData: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ob: any;
  preview: string | undefined;
  setPreview: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ open, setOpen, ob, setPreview }) => {
  // const inputRef = useRef(null);
  // const [selectedFile, setSelectedFile] = useState();
  // const [preview, setPreview] = useState<string|undefined>();

  // const handleImageChange = (e: any) => {
  //   setSelectedFile(e.target.files[0]);
  // };

  // useEffect(() => {
  //   if (!selectedFile) {
  //     setPreview(undefined);
  //     return;
  //   }
  //   const objectUrl = URL.createObjectURL(selectedFile);
  //   setPreview(objectUrl);
  //   return () => URL.revokeObjectURL(objectUrl);
  // }, [selectedFile]);

  const { accessToken } = useAppSelector((s) => s.user);

  return (
    <Formik
      initialValues={{
        name: ob?.name,
        cont_no: ob?.cont_no,
        lat: ob?.lat,
        long: ob?.long,
        addr: ob?.addr,
      }}
      onSubmit={async (values: any) => {
        console.log(values);
        values.lat = Number(values.lat);
        values.long = Number(values.long);
        await fetch(
          `https://api.cca.distronix.in:3443/caller/medicalCenter/updateOne/${ob?.id}`,
          {
            method: "PATCH",
            body: JSON.stringify(values),
            headers: {
              Authorization: `${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            // setOpen(!open);
            return response.json();
          })
          .then((data) => {
            setOpen(false);
            console.log(data);
            if (data.status === "success") {
              window.location.reload();
              console.log(data);
            } else {
              console.log(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        // .matches(
        //   /^[a-zA-Z0-9@]+$/,
        //   "This field cannot contain white space and special character"
        // ),
        cont_no: Yup.number()
          .required("Required")
          .typeError("Invalid Contact Number"),
        lat: Yup.number().required("Required").typeError("Invalid Latitude"),
        long: Yup.number().required("Required").typeError("Invalid Longitude"),
        addr: Yup.string().required("Required"),
        // .matches(
        //   /^[a-zA-Z0-9@]+$/,
        //   "This field cannot contain white space and special character"
        // ),
      })}
      enableReinitialize
    >
      {(props) => {
        const { values, touched, errors, handleSubmit, initialValues } = props;
        console.log(errors);
        return (
          <Modal
            size="tiny"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            dimmer={"blurring"}
          >
            <Modal.Header>
              {" "}
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Icon name="hospital" color="green" />
                Edit Health Center
              </p>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 15,
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <div
                      style={{
                        height: "100px",
                        width: " 200px",
                        borderLeft: "1px solid gray",
                        borderRight: "1px solid gray",
                        borderTop: " 5px solid gray",
                        borderBottom: "5px solid gray",
                      }}
                    >
                      {!!preview ? (
                        <img
                          src={preview}
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                          alt=""
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "20px",
                            marginLeft: "10px",
                          }}
                        >
                          Please select an Image for Preview
                        </div>
                      )}
                    </div>
                    <input
                      id="file"
                      type="file"
                      style={{ display: "none" }}
                      ref={inputRef}
                      onChange={handleImageChange}
                    />

                    <Button
                      id="file"
                      onClick={
                        //@ts-ignore
                        () => inputRef.current.click()
                      }
                    >
                      Change Logo
                    </Button>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 15,
                      width: "100%",
                    }}
                  >
                    <InputField
                      label="Center Name"
                      props={props}
                      name={Object.keys(initialValues)[0]}
                      value={values.name}
                      error={errors.name}
                      touched={touched.name}
                    />
                    <InputField
                      label="Contact Number"
                      props={props}
                      name={Object.keys(initialValues)[1]}
                      value={values.cont_no}
                      error={errors.cont_no}
                      touched={touched.cont_no}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 15,
                      width: "100%",
                    }}
                  >
                    <InputField
                      label="Latitude"
                      props={props}
                      name={Object.keys(initialValues)[2]}
                      value={values.lat}
                      error={errors.lat}
                      touched={touched.lat}
                    />
                    <InputField
                      label="Longitude"
                      props={props}
                      name={Object.keys(initialValues)[3]}
                      value={values.long}
                      error={errors.long}
                      touched={touched.long}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 15,
                      width: "100%",
                    }}
                  >
                    <InputField
                      label="Address"
                      props={props}
                      name={Object.keys(initialValues)[4]}
                      value={values.addr}
                      error={errors.addr}
                      touched={touched.addr}
                    />
                  </div>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <form onSubmit={handleSubmit} style={{ all: "initial" }}>
                <Button
                  // onClick={() => setOpen(false)}
                  primary
                  type="submit"
                  // disabled={isSubmitting}
                >
                  Update
                </Button>
                <Button negative onClick={() => setOpen(false)}>
                  Close
                </Button>
              </form>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditClinicData;
