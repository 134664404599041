import { useState } from "react";
import OtpInput from "react-otp-input"
import { Button, Modal } from "semantic-ui-react"

const OtpModal:React.FC<{
    open:boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({open,setOpen}) => {

    const [otp, setOtp] = useState("");

    return (
        <Modal
        onClose={() => setOpen(false)}
        open={open}
        size="mini"
      >
        <Modal.Header>Please Verify To Allocate SHC</Modal.Header>
        <Modal.Content>
          <p>OTP Send To Citizen Mobile Number Please Enter!</p>
          <OtpInput
            containerStyle={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              gap: 20,
            }}
            inputStyle={{ width: "30px", height: "30px" }}
            value={otp}
            onChange={(e: any) => setOtp(e)}
          />
        </Modal.Content>
        <Modal.Actions>
        <Button
            icon="cross"
            content="Cancel"
            onClick={() => setOpen(false)}
          />
          <Button
            icon="check"
            content="Submit"
            onClick={() => setOpen(false)}
          />
        </Modal.Actions>
      </Modal>
    )
}

export default OtpModal
