import React, { useEffect, useRef, useState } from "react";
import NKDALogo from "../../assets/images/nkda1.jpg";
import User from "../../assets/images/User.jpeg";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

const DUMMYARRAY = [
  { t: 0, l: 0 },
  { t: 0, l: 1 },
  { t: 1, l: 0 },
  { t: 1, l: 1 },
  { t: 2, l: 0 },
  { t: 2, l: 1 },
  { t: 3, l: 0 },
  { t: 3, l: 1 },
];

export const ComponentToPrint = React.forwardRef(({ value }: any, ref) => {
  const componentRef = useRef(null);
  const handlePrintFunction = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    value && handlePrintFunction();
  }, [value]);

  // const paddingTop = value?.position?.t * 195 + 40 + "px";
  // const paddingLeft = value?.position?.l * 370 + 40 + "px";

  console.log(value);
  return (
    //@ts-ignore
    <div ref={componentRef} className="grid grid-cols-2 gap-1 py-2">
      {/* Main container */}
      {DUMMYARRAY.map((item) => (
        <div className="flex items-center justify-center p-6 py-10 px-4 ">
          {item?.l === value?.position.l && item?.t === value?.position.t ? (
            <div
              className=" rounded-xl  overflow-hidden border-[1px] shadow-lg"
              style={{
                width: "321.26px",
                height: "202px",
                border: "solid",
                borderWidth: 2,
                borderColor: "#000",
                // margin: "40px",
              }}
            >
              {/* Top section  start*/}
              <div
                className="flex space-x-2"
                style={{
                  marginLeft: "0.25rem",
                  paddingTop: ".8rem",
                  paddingLeft: "0.3rem",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "1.25rem",
                      lineHeight: "1.1rem",
                      letterSpacing: ".12rem",
                      fontWeight: 600,
                    }}
                  >
                    NKDA - Smart Health Card
                  </p>
                  <div
                    style={{
                      backgroundColor: "#7FB1BE",
                      borderRadius: "0.125rem",
                      height: 8,
                      marginTop: -10,
                    }}
                  />
                </div>
                <img
                  src={NKDALogo}
                  alt="NKDA logo"
                  style={{ height: "2.5rem", width: "3.3rem" }}
                />
              </div>
              {/* Middle section start */}
              <div
                style={{
                  paddingRight: "0.6rem",
                  paddingLeft: "0.6rem",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  paddingTop: "0.8rem",
                  paddingBottom: "0.8rem",
                }}
              >
                {value?.profile_image ? (
                  <img
                    src={`https://api.cca.distronix.in:3443/${value?.profile_image}`}
                    alt="userImage"
                    style={{
                      height: "5.5rem",
                      paddingRight: "0.5rem",
                    }}
                  />
                ) : (
                  <img
                    src={User}
                    alt="userImage"
                    style={{
                      height: "5.5rem",
                      paddingRight: "0.5rem",
                    }}
                  />
                )}
                <div>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: ".5rem",
                    }}
                  >
                    Name : {value?.name}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: ".5rem",
                    }}
                  >
                    DOB :
                    {value?.date_of_birth &&
                      moment(value?.date_of_birth).format("DD/MM/YYYY")}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: ".5rem",
                    }}
                  >
                    Gender : {value?.sex === "M" ? "Male" : "Female"}
                  </p>
                  {/* <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: ".5rem",
                    }}
                  >
                    Blood Group : {value?.blood_group}
                  </p> */}
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: ".5rem",
                    }}
                  >
                    Ph No.: {value?.mob_no}
                  </p>
                </div>
                <div
                  style={{
                    height: "5rem",
                    position: "absolute",
                    bottom: "6px",
                    right: "0.6rem",
                  }}
                >
                  <QRCode
                    style={{
                      height: "auto",
                      width: "100%",
                      maxHeight: "100%",
                    }}
                    value={`http://shc.singlepage.distronix.in?citizen=${value?.id}`}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
              {/* End section start */}
              <div
                style={{
                  backgroundColor: "#7fb1be",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: 3,
                }}
              >
                <p
                  style={{ padding: "8px" }}
                  className="text-white text-2xl  font-semibold tracking-widest"
                >
                  {value?.card_no ? value?.card_no : "0123456"}
                </p>
              </div>
            </div>
          ) : (
            <div
              className=" rounded-xl  overflow-hidden "
              style={{
                width: "321.26px",
                height: "195px",
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
});
