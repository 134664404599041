import axios, { AxiosError, AxiosRequestConfig } from "axios"



export  const RegisterCard = (cardNo:string,key:string) => {

     const data ={
        card_no:cardNo
     }

        const config: AxiosRequestConfig = {
            method: 'post',
            url: 'https://api.cca.distronix.in:3443/caller/shc/register',
            headers:{
                authorization:key
            },
            data:data
        }

        const result = axios(config).then((e:any)=> e).catch((e:any)=>{
            e as AxiosError
            return e
        }
        )

        return result

}