import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import InputField from "../InputField/InputField";
import OtpModal from "./OtpModal";
import { AddShcUser } from "../../api/ShcUser/addShcUser";
import { useAppSelector } from "../../app/hooks";
import { GetAllRegistered } from "../../api/CardDataApi/getAllRegistered";
import { useAlert } from "react-alert";
import DropDown from "../Dropdown";
import { Alert } from "@mui/material";
import { BloodGroupGenerator } from "../../util/HelpperFun";

const AllocateHSC: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const [preview, setPreview] = useState<string | undefined>();
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [registeredIdData, setRegisteredIdData] = useState<any[]>([]);
  const alert = useAlert();

  const { accessToken } = useAppSelector((s) => s.user);

  const handleImageChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const setRegistedId = async () => {
    const response = await GetAllRegistered(accessToken);
    setRegisteredIdData(response?.data?.data);
  };

  useEffect(() => {
    setRegistedId();

    return () => {
      setRegisteredIdData([]);
    };
  }, [open]);

  const initialValues = {
    ShcId: "",
    name: "",
    number: "",
    alternateNumber: "",
    emergencyNumber: "",
    bloodGroup: "",
    BP: "",
    Diabetes: "",
    Dob: "",
    gender: "",
  };
  const onSubmitHandler = async (values: any, formik: any) => {
    let data = new FormData();
    data.append("name", values.name);
    data.append("mob_no", values.number);
    data.append("alternative_mob_no", values.alternateNumber);

    if (BloodGroupGenerator(values.bloodGroup) !== "Select") {
      data.append("blood_group", BloodGroupGenerator(values.bloodGroup));
    }
    data.append("date_of_birth", values.Dob);
    data.append(
      "sex",
      values.gender === "Male" ? "M" : values.gender === "Female" ? "F" : "O"
    );
    data.append(
      "diabetes_present",
      values.Diabetes === "Yes" ? "true" : "false"
    );
    data.append("high_bp", values.BP === "Yes" ? "true" : "false");
    //@ts-ignore
    data.append("profile_image", selectedFile);

    if (selectedFile) {
      let response = await AddShcUser(accessToken, data);

      if (response?.data?.status === "success") {
        setOpen(false);
        alert.success(response?.data?.message);
        formik.resetForm();
        setSelectedFile(null);
      } else {
        setOpen(false);
        alert.error(response?.data?.message || "Something went wrong");
      }
    } else {
      setOpen(false);
      alert.error("Please select a image");
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(6).required("Required"),
    number: Yup.number().min(10).required("Required"),
    Dob: Yup.string().min(2).required("Required"),
    gender: Yup.string().min(2).required("Required"),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formik) => {
          onSubmitHandler(values, formik);
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            initialValues,
          } = props;
          // console.log(errors);
          return (
            <Modal
              size="small"
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              dimmer={"blurring"}
            >
              <Modal.Header>Allocate Smart Health Card</Modal.Header>

              <Modal.Content scrolling>
                <div className=" py-4 flex justify-center items-center space-x-2 px-4">
                  <div
                    style={{
                      height: "120px",
                      width: " 180px",
                      border: "1px solid gray",
                    }}
                    className=" rounded-lg shadow-lg p-1"
                  >
                    {!!preview ? (
                      <img
                        src={preview}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                        }}
                        className=" text-center mt-10"
                      >
                        Please select an Image for Preview
                      </div>
                    )}
                  </div>
                  <input
                    id="file"
                    type="file"
                    style={{ display: "none" }}
                    ref={inputRef}
                    onChange={handleImageChange}
                  />

                  <Button
                    id="file"
                    onClick={
                      //@ts-ignore
                      () => inputRef.current.click()
                    }
                    className=" shadow-xl rounded-lg"
                  >
                    Add Photo <span className="text-red-600 -mt-4">*</span>
                    <Icon
                      //@ts-ignore
                      name="cloud upload right"
                    />
                  </Button>
                </div>
                <Modal.Description>
                  <div
                    style={{
                      gap: 15,
                    }}
                    className=" grid grid-cols-2"
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 15,
                        width: "100%",
                      }}
                    > */}
                    {/* <InputField
                        label="Registered SHC Id"
                        props={props}
                        name={Object.keys(initialValues)[0]}
                        registeredIdData={registeredIdData}
                        value={values.ShcId}
                        error={errors.ShcId}
                        touched={touched.ShcId}
                      /> */}
                    <InputField
                      label="Full Name"
                      props={props}
                      name={Object.keys(initialValues)[1]}
                      value={values.name}
                      error={errors.name}
                      touched={touched.name}
                      asterisk={true}
                    />
                    {/* <InputField
                        label="Gender"
                        props={props}
                        name={Object.keys(initialValues)[9]}
                        value={values.gender}
                        error={errors.gender}
                        touched={touched.gender}
                      /> */}
                    <DropDown
                      props={{
                        options: ["Male", "Female", "Others"],
                        name: "gender",
                        label: "Gender",
                        asterisk: true,
                        value: undefined,
                        error: undefined,
                      }}
                    />

                    <InputField
                      label="Phone No."
                      props={props}
                      name={Object.keys(initialValues)[2]}
                      value={values.number}
                      error={errors.number}
                      touched={touched.number}
                      asterisk={true}
                    />
                    <InputField
                      label="Date Of Birth"
                      props={props}
                      name={Object.keys(initialValues)[8]}
                      value={values.Dob}
                      error={errors.Dob}
                      touched={touched.Dob}
                      asterisk={true}
                    />
                    <InputField
                      label="Alternate No."
                      props={props}
                      name={Object.keys(initialValues)[3]}
                      value={values.alternateNumber}
                      error={errors.alternateNumber}
                      touched={touched.alternateNumber}
                    />
                    <InputField
                      label="Emergency No."
                      props={props}
                      name={Object.keys(initialValues)[4]}
                      value={values.emergencyNumber}
                      error={errors.emergencyNumber}
                      touched={touched.emergencyNumber}
                    />
                    <DropDown
                      props={{
                        options: [
                          "A Positive",
                          "A Negative",
                          "B Positive",
                          "B Negative",
                          "O Positive",
                          "O Negative",
                          "AB Positive",
                          "AB Negative",
                        ],
                        name: "bloodGroup",
                        label: "Blood Group ",
                        asterisk: undefined,
                        value: undefined,
                        error: undefined,
                      }}
                    />
                    {/* </div> */}

                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 15,
                        width: "100%",
                      }}
                    > */}
                    <DropDown
                      props={{
                        options: ["Yes", "No"],
                        name: "BP",
                        label: "BP",
                        asterisk: undefined,
                        value: undefined,
                        error: undefined,
                      }}
                    />
                    <DropDown
                      props={{
                        options: ["Yes", "No"],
                        name: "Diabetes",
                        label: "Diabetes",
                        asterisk: undefined,
                        value: undefined,
                        error: undefined,
                      }}
                    />

                    {/* </div> */}

                    {/* <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        gap: 15,
                        width: "100%",
                      }}
                      className="pl-4"
                    > */}

                    {/* </div> */}
                  </div>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color="grey" onClick={() => setOpen(false)}>
                  Close
                </Button>

                <form onSubmit={handleSubmit} style={{ all: "initial" }}>
                  <Button
                    // onClick={() => setSecondOpen(true)}
                    primary
                    type="submit"
                    // disabled={isSubmitting}
                    className="ui primary button"
                  >
                    Create{" "}
                    <Icon
                      //@ts-ignore
                      name="add user right"
                    />
                  </Button>
                </form>
              </Modal.Actions>
              <OtpModal open={secondOpen} setOpen={setSecondOpen} />
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default AllocateHSC;
