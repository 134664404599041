import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Button, Icon,  Modal } from "semantic-ui-react";
import InputField from "../InputField/InputField";
import OtpModal from "./OtpModal";

const DuplicateHSC: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const [thirdOpen, setThirdOpen] = useState(false);

  const handleThirdModal = () => {
    setThirdOpen(false)
    setSecondOpen(false)
    setOpen(false)
  }

  return (
    <Formik
      initialValues={{ number: null, id: null }}
      onSubmit={(values, actions) => {
        console.log(values);
      }}
      validationSchema={Yup.object().shape({
        number: Yup.number().typeError("Invalid Number").required("Required"),
        ID: Yup.number().typeError("Invalid id").required("Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          initialValues,
        } = props;
        console.log(errors);
        return (
          <Modal
            size="tiny"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            dimmer={"blurring"}
          >
            <Modal.Header>
              {" "}
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Icon name="hospital" color="green" />
                Generate Duplicate Smart Health Card
              </p>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 15,
                      width: "100%",
                    }}
                  >
                   
                      <InputField
                        label="Enter Citizen Mobile Number"
                        props={props}
                        name={Object.keys(initialValues)[0]}
                        value={values.number}
                        error={errors.number}
                        touched={touched.number}
                      />
           
                  </div>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={() => setOpen(false)}>
                Close <Icon name="close" />
              </Button>
              <form onSubmit={handleSubmit} style={{ all: "initial" }}>
                <Button
                  onClick={() => setSecondOpen(true)}
                  primary
                  type="submit"
                  // disabled={isSubmitting}
                >
                  Get OTP <Icon name="send" />
                </Button>
              </form>
            </Modal.Actions>
            <Modal
            size="tiny"
            open={thirdOpen}
            onClose={() => setThirdOpen(false)}
            onOpen={() => setThirdOpen(true)}
            dimmer={"blurring"}
          >
            <Modal.Header>
              {" "}
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Icon name="hospital" color="green" />
                Generate Duplicate Smart Health Card
              </p>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 15,
                      width: "100%",
                    }}
                  >
                    
                      <InputField
                        label="Enter Duplicate SHC ID"
                        props={props}
                        name={Object.keys(initialValues)[1]}
                        value={values.id}
                        error={errors.id}
                        touched={touched.id}
                      />
                    
                  </div>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={() => setOpen(false)}>
                Close <Icon name="close" />
              </Button>
              <form onSubmit={handleSubmit} style={{ all: "initial" }}>
                <Button
                  onClick={handleThirdModal}
                  primary
                  type="submit"
                  // disabled={isSubmitting}
                >
                  Submit <Icon name="send" />
                </Button>
              </form>
            </Modal.Actions>
            </Modal>
            <OtpModal
                 open={secondOpen}
                 setOpen={setThirdOpen}
               />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default DuplicateHSC;
