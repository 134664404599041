import { Popper } from "@mui/material";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { FaClinicMedical } from "react-icons/fa";
import { GetMapData } from "../../api/Map/getMapData";
import { useAppSelector } from "../../app/hooks";
import { clinicData, mockClinicData } from "../../assets/mockData/clinicData";
import Marker from "../../components/Marker/Marker";
import { flatenData } from "../MasterData/PatientTransction";
// import "./style.css";

export interface Datum {
  citizen_id: number;
  name: string;
  mob_no: string;
  profile_image: string;
  count: number;
}

export interface IMapData {
  lat: number;
  long: number;
  data: Datum[];
  center_name: string;
}

const Map = () => {
  const [clicked, setClicked] = React.useState<number | null>(null);
  const [mapData, setMapData] = React.useState<IMapData[]>([]);

  const { accessToken } = useAppSelector((state) => state.user);

  const loadMapData = async () => {
    const data = await GetMapData(accessToken);
    if (data.status === 200) {
      setMapData(data?.data?.data);
    }
    console.log("Map Data --->", data?.data?.data);
  };

  useEffect(() => {
    loadMapData();
  }, []);
  // MAP DEV KEY = AIzaSyAut8wMgiwUlNdnp0BGlpbG4Jc0umurpM0
  // MAP PUB KEY = AIzaSyC6IjsKHi8RLOzm7oGGS9vw0uYQcs87nYA
  return (
    <div style={{ flex: 1 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC6IjsKHi8RLOzm7oGGS9vw0uYQcs87nYA" }}
        defaultCenter={{
          lat: 22.5754,
          lng: 88.4798,
        }}
        defaultZoom={12}
      >
        {mapData.map((item) => {
          if (!!item.lat && !!item.long) {
            return (
              <Marker
                key={item.lat}
                id={item.lat}
                lat={item.lat}
                lng={item.long}
                centerName={item?.center_name}
                data={item.data}
                clicked={clicked}
                setClicked={setClicked}
              />
            );
          } else {
            return null;
          }
        })}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
