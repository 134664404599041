import { FormikErrors, FormikTouched } from "formik";
import { useEffect, useState } from "react";
import { Button, Icon, Input } from "semantic-ui-react";
import { useAppSelector } from "../../app/hooks";
import OtpModal from "../Modal/OtpModal";

const InputField: React.FC<{
  asterisk?: boolean;
  label: string;
  name: string;
  registeredIdData?: any[];
  value: string | number | null | any;
  error:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  props: any;
}> = ({
  label,
  name,
  registeredIdData,
  value,
  error,
  touched,
  props,
  asterisk,
}) => {
  const { handleChange, handleBlur } = props;

  const [hide, setHide] = useState(true);
  const [open, setOpen] = useState(false);

  const { accessToken } = useAppSelector((state) => state.user);

  const iconGenerator = () => {
    if (label === "User Name") {
      return "user";
    }
    return "";
  };
  const inputType = () => {
    if (label === "Password" && hide) {
      return "text";
    } else if (label === "Date Of Birth") {
      return "date";
    }

    return "text";
  };

  const listType = () => {
    if (label === "Gender") {
      return "gender";
    } else if (label === "Registered SHC Id") {
      return "Registered SHC Id";
    } else if (label === "Blood Group") {
      return "Blood Group";
    } else if (label === "BP") {
      return "BP";
    } else if (label === "Diabetes") {
      return "Diabetes";
    } else {
      return null;
    }
  };

  const handleClick = () => {
    setHide((prev) => !prev);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginBottom:
          label === "User Name" || label === "Password" ? "10px" : "0px",
      }}
      className="flex flex-grow"
    >
      <OtpModal open={open && label === "Mobile Number"} setOpen={setOpen} />
      {label !== "User Name" && label !== "Password" && (
        <label>
          {label} {asterisk && <span className="text-red-600 -mt-4">*</span>}
        </label>
      )}
      <Input
        list={listType()}
        className={`flex flex-grow `}
        name={name}
        //@ts-ignore
        icon={
          label === "Email" || label === "Mobile Number" ? (
            <Button disabled={!!error} onClick={() => setOpen(true)}>
              Verify
            </Button>
          ) : (
            <Icon
              //@ts-ignore
              name={iconGenerator()}
              link={label === "Password" ? true : false}
              onClick={handleClick}
            />
          )
        }
        placeholder={label}
        // style={{ width: label !== "Address" ? 240 : 492 }}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!error ? true : false}
        type={inputType()}
        autoComplete="off"
      />

      {label === "Registered SHC Id" && (
        <datalist id="Registered SHC Id">
          {registeredIdData?.map((item: any) => (
            <option value={item.card_no}>{item.card_no}</option>
          ))}
        </datalist>
      )}
      {label === "Gender" && (
        <datalist id="gender">
          <option value="M">Male</option>
          <option value="F">Female</option>
          <option value="O">Other</option>
        </datalist>
      )}
      {label === "Blood Group" && (
        <datalist id="Blood Group">
          <option value="A+">A+</option>
          <option value="A-">A-</option>
          <option value="B+">B+</option>
          <option value="B-">B-</option>
          <option value="O+">O+</option>
          <option value="O-">O-</option>
          <option value="AB+">AB+</option>
          <option value="AB-">AB-</option>
        </datalist>
      )}
      {label === "BP" && (
        <datalist id="BP">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </datalist>
      )}
      {label === "Diabetes" && (
        <datalist id="Diabetes">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </datalist>
      )}

      {/* {!!error && !!touched && (
        <div
          style={{
            color: "red",
            marginTop: ".25rem",
          }}
        >
          {
            //@ts-ignore
            error.split(",")[0]
          }
        </div>
      )} */}
    </div>
  );
};

export default InputField;
