import React, { useEffect } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import Dashbord from "./pages/Dashbord/Dashbord";
import Map from "./pages/Map/Map";
import CssBaseline from "@mui/material/CssBaseline";
import Login from "./components/Login/Login";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import MedicalCenter from "./pages/MasterData/MedicalCenter";
import "react-confirm-alert/src/react-confirm-alert.css";
import PatientData from "./pages/MasterData/PatientData";
import PatientTransction from "./pages/MasterData/PatientTransction";
import { CitizenTransactionData } from "./api/CitizenData/citizenTranscation";
import { addTransaction } from "./features/document/transactionSlice";
import DashboardNew from "./pages/Dashbord/DashboardNew";
import UploadDocument from "./pages/MasterData/UploadDocument";

function App() {

  const { accessToken } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch()

  const loadTransactionData = async () => {

    const data = await CitizenTransactionData(accessToken)
    // console.log(data?.data?.data)
    if (data?.status === 200) {
      dispatch(addTransaction(data?.data?.data))
    }

  }

  useEffect(() => {

    loadTransactionData()

  }, [])

  return (
    <>
      {/* <CssBaseline /> */}
      {accessToken === "" ? (
        <Login />
      ) : (
        <div
          className="flex h-screen w-full overflow-hidden"
        >
          <Router>
            <Sidebar />
            <div
              className="flex flex-col min-h-screen w-full overflow-y-auto"
            >
              <Header />
              <Switch>
                <Route path="/masterdata/patient">
                  <PatientData />
                </Route>
                <Route path="/masterdata/medicalcenter">
                  <MedicalCenter />
                </Route>
                <Route path="/masterdata/patienttransaction">
                  <PatientTransction />
                </Route>
                <Route path="/map">
                  <Map />
                </Route>
                <Route path="/uploaddocument">
                  <UploadDocument />
                </Route>
                <Route exact path="/">
                  <DashboardNew />
                </Route>
              </Switch>
            </div>
          </Router>
        </div>
      )}
    </>
  );
}

export default App;
